export default function InviteForm({ handleChange, formData, handleSubmit, accountName }) {
    return(
        <div className="login-form-container round-div div-shadow">
            <p className="header">{`By accepting this invitation, you will be joining ${ accountName }'s workspace. Please fill your information:`}</p>
            <form onSubmit={ handleSubmit } className="login-form">
                <label>
                    First Name:
                    <input type="text" name="name" placeholder="John" autoComplete="on" className="round-btn" onChange={ handleChange } value={ formData.name }/>
                </label>
                <label>
                    Last Name:
                    <input type="text" name="lastname" placeholder="Doe" autoComplete="on" className="round-btn" onChange={ handleChange } value={ formData.lastname }/>
                </label>
                <label>
                Password:
                    <input type="password" name="password" placeholder="*****" autoComplete="off" className="round-btn" onChange={ handleChange } value={ formData.password }/>
                </label>
                <label>
                    Repeat Password:
                    <input type="password" name="checkPassword" placeholder="*****" autoComplete="off" className="round-btn" onChange={ handleChange } value={ formData.checkPassword }/>
                </label>
            </form>
            <button onClick={ handleSubmit } className="login-btn btn">
                Join Workspace
            </button>
        </div>
    )
}