import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faXmark } from '@fortawesome/free-solid-svg-icons'
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

export default function MenuBtn({ visible, toggleVisible }) {

    //CONTEXT
    const { darkMode } = useContext( AppContext )
    return(
        <div className={`menu-btn-container mobile ${ !darkMode ? 'light' : ''}`} onClick={ toggleVisible }>
            { !visible ? 
                <FontAwesomeIcon icon={ faEllipsisV }/>
                :
                <FontAwesomeIcon icon={ faXmark }/>
            }
        </div>
    )
}