import { NavLink } from "react-router-dom"
import { useContext } from "react"
import { AppContext } from "../../context/AppContext"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function SideBarBtn({ text, icon, route, desktop, toggleVisible }) {

    //CONTEXT
    const { sideBarExpanded, darkMode } = useContext(AppContext)

    // //FUNCTIONS
    // const handleClick = () => {
    //     if( toggleVisible ) {
    //         toggleVisible()
    //     }
    // }

    return(
        <li>
            <NavLink to={ route } onClick={ toggleVisible }>
                <div className={`nav-btn round-btn ${!sideBarExpanded ? 'centered' : ''} ${ !darkMode ? 'light' : ''} ${ !desktop ? 'mobile' : ''}`}>
                    { desktop && <FontAwesomeIcon icon={icon}/> }
                    
                    {
                        sideBarExpanded && 
                        <p className={`${sideBarExpanded ? 'full-width' : 'no-width'}`}>{text}</p>
                    }
                </div>
            </NavLink>
        </li>

    )
}