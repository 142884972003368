import { AppContext } from '../context/AppContext'
import { useContext } from 'react'
import SideBarContainer from '../components/SideBarContainer/SideBarContainer'
import BoardContainer from '../components/BoardContainer/BoardContainer'
import AdminPanelContainer from '../components/AdminPanelContainer/AdminPanelContainer'
import Login from './Login'
import GlobalLoader from '../components/GlobalLoader/GlobalLoader'
import TopBarContainer from '../components/TopBarContainer/TopBarContainer'


export default function Admin() {

    const { globalUser, globalLoader, darkMode } = useContext(AppContext)
  
    return(
        <>
        { globalLoader ?
            <GlobalLoader/>
            :
            <>
            { !globalUser ?
                <Login />
                :
                <div className={`view-container ${ !darkMode ? 'light' : ''}`}>
                    <SideBarContainer/>
                    <TopBarContainer/>
                    <BoardContainer sectionTitle={ 'Admin Panel' } faded={ false } section={ <AdminPanelContainer/> }/>
                </div>
            }
            </>
        }
        </>
    )
}