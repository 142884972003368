import { useState } from "react"
import GoogleSignInBtn from "../GoogleSignInBtn/GoogleSignInBtn"
import Swal from "sweetalert2";
import LoginForm from "../LoginForm/LoginForm"
import OnboardingForm from "../OnboardingForm/OnboardingForm"
import useAccounts from "../../hooks/useAccounts"
import { Link } from "react-router-dom";

export default function LoginFormContainer({ handleLoader }) {

    //STATES
    const [ isLogging, setIsLogging ] = useState( true )
    const [ formData, setFormData ] = useState( {
        name: '',
        lastname: '',
        company: '',
        email: '',
        password: '',
        checkPassword: ''

    } )
    const [ showOnboardingForm, setShowOnboardingForm ] = useState( false )
    const [ googleUser, setGoogleUser ] = useState( {} )

    //HOOKS
    const { userLogin, handleNewUser, googleSignIn } = useAccounts()


    //FUNCTIONS 
    const handleChange = ( e ) => {

        //DESTRUCTURES event FROM INPUT
        const { name, value } = e.target

        //UPDATES formData STATE
        setFormData( prevFormData => ({
            ...prevFormData,
            [ name ]: value
        }))
    }

    const handleIsLogging = ( bool ) => {
        setIsLogging( bool )
    }

    const handleSubmit = async ( e ) => {

        //PREVENTS DEFAULT BROWNSER RELOAD
        e.preventDefault()

        //TURNS LOADER ON
        handleLoader( true )

        //IS USER IS LOGGIN IN:
        try{

            if( isLogging ){

                await userLogin( formData )

            } else {

                await handleNewUser( formData, false )
            }

            handleLoader( false )

        } catch ( err ){

            handleLoader( false )

            Swal.fire({
                title: 'Oops!',
                text: err.message,
                icon: 'warning',
                confirmButtonText: 'Ok',
                buttonsStyling: false,
                customClass: {
                    popup: 'marketsauce-alert-container round-div div-shadow',
                    icon: 'alert-icon',
                    confirmButton: 'confirm-btn btn order2',
                    denyButton: 'deny-btn btn order1',
                }
            })
        }
    }

    const handleGoogleSignIn =  async ( e ) => {
        // PREVENTS BROWSER FROM RELOADING
        e.preventDefault()

        try{
            const userInfo = await googleSignIn()

            if( userInfo.isNewUser ) {

                //IF USER IS NEW, REVEALS ONBOARDING FORM
                setShowOnboardingForm( true )

                //UPDATES googleUser
                setGoogleUser({
                    name: userInfo.profile.family_name,
                    lastname: userInfo.profile.given_name,
                    email: userInfo.profile.email,
                })
            } 

        } catch ( err ) {
            console.log( err );

        }
    }

    return(
        <div className="login-form-container round-div div-shadow">
            { !showOnboardingForm ?
                <>
                <LoginForm isLogging={ isLogging } handleIsLogging={ handleIsLogging } handleSubmit={ handleSubmit } handleChange={ handleChange } formData={ formData } showOnboardingForm={ showOnboardingForm } />
                <GoogleSignInBtn handleGoogleSignIn={ handleGoogleSignIn } />
                <Link to={'/reset'}>Forgot your password?</Link>
                </>
                :
                <OnboardingForm googleUser={ googleUser } handleLoader={ handleLoader } createUser={ handleNewUser } />
            }
        </div>
    )
}