import { useContext } from 'react'
import { AppContext } from '../context/AppContext'
import SideBarContainer from '../components/SideBarContainer/SideBarContainer'
import BoardContainer from '../components/BoardContainer/BoardContainer'
import Login from './Login'
import GlobalLoader from '../components/GlobalLoader/GlobalLoader'
import AccountInactiveMessage from '../components/AccountInactiveMessage/AccountInactiveMessage'
import TopBarContainer from '../components/TopBarContainer/TopBarContainer'
import AvatarChatContainer from '../components/AvatarChatContainer/AvatarChatContainer'

export default function ChatAvatar() { 

    //CONTEXT
    const { globalUser, globalLoader, globalAccount } = useContext(AppContext)

    return(
        <>
        { globalLoader ?
            <GlobalLoader/>
            :
            <>
            { !globalUser?
                <Login/>
                :
                <div className='view-container'>
                    <SideBarContainer/>
                    <TopBarContainer/>
                    <BoardContainer sectionTitle={ 'Chat' } faded={ false } section={ globalAccount && !globalAccount.isActive ?
                    <AccountInactiveMessage/> : <AvatarChatContainer/>} />
                </div>
            }
            </> 
        }
        </>
    )
}