import { useState } from "react";
import LoginFormContainer from "../components/LoginFormContainer/LoginFormContainer";
import Loader from "../components/Loader/Loader";
import Footer from "../components/Footer/Footer";


export default function Login() {

    //STATE
    const [ isLoading, setIsLoading ] = useState ( false )


    //FUNCTIONS
    const handleLoader = ( bool ) => {
        setIsLoading( bool )
    }

    return(
        <>
        <div className="view-container login">
            {isLoading ?
                <Loader/>
                :
                <LoginFormContainer handleLoader={ handleLoader } />
            }
        </div>
        <Footer/>
        </>
    )
}