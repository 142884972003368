import { useState, useContext, useEffect, useCallback } from "react";
import Loader from "../Loader/Loader";
import ItemRow from "../ItemRow/ItemRow";
import useAccounts from "../../hooks/useAccounts";
import { AppContext } from "../../context/AppContext";
import NewInput from "../NewInput/NewInput";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import BackBtn from "../BackBtn/BackBtn";


export default function TeamContainer() {

    //STATE
    const [ isLoading, setIsLoading ] = useState( true )
    const [ accountUsers, setAccountUsers ] = useState( null )
    const [ accountInvitations, setAccountInvitations ] = useState( null )

    //ROUTER
    const navigate = useNavigate()

    //HOOKS
    const { getAccountUsers, createInvitation, getAccountInvitations } = useAccounts()

    //CONTEXT
    const { darkMode, globalAccount, globalUser } = useContext( AppContext )

    //FUNCTIONS
    const toggleLoader = ( bool ) => {
        setIsLoading( bool )
    }

    const getUsers = useCallback( async ( id ) => {

        const users = await getAccountUsers( id )
    
        setAccountUsers( users )

    }, [ getAccountUsers ] )

    const getInvitations = useCallback( async ( id ) => {

        const invitations = await getAccountInvitations( id )
        setAccountInvitations( invitations )

    }, [ getAccountInvitations ] )

    const handleAddUser = async ( email ) => {

        try{
            setIsLoading( true )
            await createInvitation( globalAccount, email )
            const data = await getAccountInvitations( globalAccount.id )
            setAccountInvitations( data )
            setIsLoading( false )

        } catch ( err ) {

            setIsLoading( false )
            Swal.fire({
                title: 'Oops!',
                text: err.message,
                icon: 'error',
                confirmButtonText: 'Ok',
                buttonsStyling: false,
                customClass: {
                    popup: 'marketsauce-alert-container round-div div-shadow',
                    icon: 'alert-icon',
                    confirmButton: 'confirm-btn btn order2',
                    denyButton: 'deny-btn btn order1',
                }
            })
        }
    }

    const handleDeleteUser = async ( id ) => {
        try {

        } catch ( err ){
            Swal.fire({
                title: 'Oops!',
                text: err.message,
                icon: 'error',
                confirmButtonText: 'Ok',
                buttonsStyling: false,
                customClass: {
                    popup: 'marketsauce-alert-container round-div div-shadow',
                    icon: 'alert-icon',
                    confirmButton: 'confirm-btn btn order2',
                    denyButton: 'deny-btn btn order1',
                }
            })
        }
    }

    const handleDeleteInvitation = async () => {

        try {

        } catch ( err ){
            Swal.fire({
                title: 'Oops!',
                text: err.message,
                icon: 'error',
                confirmButtonText: 'Ok',
                buttonsStyling: false,
                customClass: {
                    popup: 'marketsauce-alert-container round-div div-shadow',
                    icon: 'alert-icon',
                    confirmButton: 'confirm-btn btn order2',
                    denyButton: 'deny-btn btn order1',
                }
            })
        }

    }


    //EFFECTS
    useEffect(() => {

        if( globalAccount ){

            getUsers( globalAccount.id )
            getInvitations( globalAccount.id )
        }
    }, [ getUsers, globalAccount, getInvitations ])

    useEffect(() => {

        if( globalAccount && accountUsers && accountInvitations ){

            setIsLoading( false )
        }
    }, [ globalAccount, accountUsers, accountInvitations ] )



    return(
        <div className={`team main-container ${ !darkMode ? 'light' : '' }`}>
            <BackBtn action={ ()=> navigate('/settings') }/>
            <div className="inner-container round-div div-shadow">
                {   isLoading ? 
                    <Loader/>
                    :
                    <>
                        <NewInput h4={ 'Add User' } placeholder={'New User email'} submitFunction={ handleAddUser } toggleLoader={ toggleLoader } />
                        { accountInvitations.length > 0 && 
                        
                            <>
                            <h5>Pending Invitations</h5>
                            { accountInvitations.map((item, idx) => {

                                const invitation = {
                                    ...item,
                                    displayName: item.email
                                }
                                return(

                                    <ItemRow key={ idx } item={ invitation } toggleLoader={ toggleLoader } editItem={ null } deleteItem={ handleDeleteUser } editable={ false }/>
                                )
                            })}
                            </>

                        }
                        <h5>Active Users</h5>
                        <div className="item-list-container">
                            {
                                accountUsers && accountUsers.map(( item, idx) => {

                                    const displayName = `${item.email} ${ item.email === globalUser.email ? '(you)': ''}`
                                    const user = {
                                        ...item,
                                        displayName
                                    }

                                    return(

                                        <ItemRow key={ idx } item={ user } toggleLoader={ toggleLoader } editItem={ null } deleteItem={ handleDeleteUser } editable={ false }/>
                                    )
                                })
                            }
                        </div>
                    </>
                }

            </div>

        </div>
    )
}