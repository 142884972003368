import { useEffect, useContext, useState } from 'react'
import { AppContext } from '../context/AppContext'
import SideBarContainer from '../components/SideBarContainer/SideBarContainer'
import BoardContainer from '../components/BoardContainer/BoardContainer'
import Login from './Login'
import GlobalLoader from '../components/GlobalLoader/GlobalLoader'
import TopBarContainer from '../components/TopBarContainer/TopBarContainer'
import AccountInactiveMessage from '../components/AccountInactiveMessage/AccountInactiveMessage'
import Footer from '../components/Footer/Footer'
import PromptsContainer from '../components/PromptsContainer/PromptsContainer'

export default function Prompts() {

    //STATE
    const [ isLoading, setIsLoading ] = useState( true )

    //CONTEXT
    const { userAvatars, globalLoader, globalAccount, globalUser } = useContext( AppContext )
    
    //HOOKS

    //FUNCTIONS
    

    //EFFECTS
    useEffect(() => {

        //LOADER IS TURNED OFF AFTER userAvatars IS RECEIVED, WHEATHER IT IS AN EMPTY ARRAY OR NOT.
        if( userAvatars ){
            setIsLoading( false )
        }
    }, [ userAvatars ])


    return(
        <>
        { globalLoader ?
            <GlobalLoader/>
            :
            <>
            { !globalUser ?
                <Login/>
                :
                <>
                    <div className={`view-container`}>
                        <SideBarContainer/>
                        <TopBarContainer/>
                        <BoardContainer sectionTitle={ `Prompt Library` } faded={ true } section={ globalAccount && !globalAccount.isActive ?
                        <AccountInactiveMessage/> : <PromptsContainer/> } isLoading={ isLoading } />
                    </div>
                    <Footer/>
                </>
            }
            </>
        }
        </>
    )
}