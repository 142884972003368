import { faCheck, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export default function OnboardingStep({ step, active, complete, handleClick }) {

    

    return(
        <div className="step-main">
            <div className={`onboarding-step-card round-div ${ !active ? 'inactive': ''}`}>
                <div className="title-container">
                    {
                        complete &&
                        <FontAwesomeIcon icon={ faCheck } className="step-checkmark"/>
                    }
                    <h4>{ step.title }</h4>
                </div>
                {
                    active &&
                    <>
                        <p>{ step.description }</p>
                        <button className="onboarding-step-btn btn" onClick={ () => handleClick( step.link ) }>
                            { step.btnText }
                            {/* <Link to={ active ? step.link : null } >
                            </Link> */}
                        </button>
                    </>
                }
            </div>
            {
                active &&
                <FontAwesomeIcon icon={ faChevronRight} className="onboarding-chevron"/>
            }
        </div>
    )
}