import { useState, useContext, useEffect, useCallback } from "react";
import Loader from "../Loader/Loader";
import { AppContext } from "../../context/AppContext";
import Swal from "sweetalert2";
import BackBtn from "../BackBtn/BackBtn";
import { useNavigate } from "react-router-dom";
import useAccounts from "../../hooks/useAccounts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import useProducts from "../../hooks/useProducts";

export default function CreditsContainer() {

    //STATE
    const [ isLoading, setIsLoading ] = useState( true )
    const [ accountTransactions, setAccountTransactions ] = useState([])
    const [ showProducts, setShowProducts ] = useState( false )
    const [ products, setProducts ] = useState( null )

    //HOOKS
    const { getAccountTransactions } = useAccounts()
    const { getAllProducts, createCheckoutSession } = useProducts()

    //ROUTER
    const navigate = useNavigate()

    //CONTEXT
    const { darkMode, globalAccount } = useContext( AppContext )

    //FUNCTIONS
    const getTransactions = useCallback( async () => {

        const array = await getAccountTransactions( globalAccount.id )
        if( array.length > 0 ){
            array.sort(( a,b ) => a.timestamp - b.timestamp )
            setProducts( array )
            setIsLoading( false )
        }
        setAccountTransactions( array )
        setIsLoading( false )

    }, [ getAccountTransactions, globalAccount ])

    const getProducts = useCallback( async () => {

        const array = await getAllProducts()
        if( array.length > 0 ){
            array.sort(( a,b ) => a.order - b.order )
            setProducts( array )
            setIsLoading( false )
        }

    }, [ getAllProducts ])

    const togglePurchaseView = () => {
        setShowProducts(!showProducts)
    }

    const handleCheckoutSession = async ( productId ) => {
        try {
            setIsLoading( true )
            const accountId = globalAccount.id
            const sessionUrl = await createCheckoutSession( accountId, productId )
            setIsLoading( false )
            window.location.href = sessionUrl
            
        } catch (error) {
            Swal.fire({
                title: 'Oops!',
                text: error.message,
                icon: 'warning',
                timer: 2500,
                confirmButtonText: 'Ok',
                buttonsStyling: false,
                customClass: {
                    popup: 'marketsauce-alert-container round-div div-shadow',
                    icon: 'alert-icon',
                    confirmButton: 'confirm-btn btn order2',
                    denyButton: 'deny-btn btn order1',
                }
            })
        }
    }


    //EFFECTS
    useEffect(() => {
        if ( globalAccount ){
            getTransactions()
        }
    }, [ getTransactions, globalAccount ])

    useEffect(() => {
        if ( showProducts ){
            getProducts()
        }

    }, [ showProducts, getProducts ])


    return(
        <div className={`credits main-container ${ !darkMode ? 'light' : '' }`}>
            <BackBtn action={ ()=> navigate('/settings') }/>
            <div className="inner-container round-div div-shadow">
                {   isLoading ? 
                    <Loader/>
                    :
                    <>
                    { !showProducts ?
                        <>
                            <h4>Balance</h4>
                            <div className="credit-balance-container credit-section">
                                <p>{ globalAccount.credits > 0 ? `Your account has ${ globalAccount.credits } credits.` : 'There are no credits in your account.'}</p>
                                <button className="purchase-button" onClick={ togglePurchaseView }>
                                    Buy Credits
                                </button>
                            </div>
                            
                            <div className="credits-transaction-container credit-section round-div">
                                <h4>Transactions</h4>
                                <div className="credit-row header">
                                    <p className="size-m">Date</p>
                                    <p className="size-m">Description</p>
                                    <p className="size-s">Credit</p>
                                </div>
                                {
                                    accountTransactions?.length > 0 ?

                                    accountTransactions.map(( transaction, idx ) => {

                                        const date = new Date( transaction.timestamp )
                                        const options =  { year: '2-digit', month: '2-digit', day: '2-digit' }
                                        const formattedDate = date.toLocaleDateString( 'en-US', options )

                                        return(
                                            <div className={`credit-row ${ idx === accountTransactions.length -1 ? 'last' : ''}`} key={ idx }>
                                                <p className="size-m">{ formattedDate }</p>
                                                <p className="size-m">{ transaction.description }</p>
                                                <p className="size-s">{`${ transaction.type === 'deduction' ? '-' : '+'} ${ transaction.creditAmount }`}</p>
                                            </div>
                                        )
                                    })
                                    :
                                    <div>
                                        <p>No Transactions to show</p>
                                    </div>
                                    
                                }
                            </div>

                        </>
                        :
                        <>
                            {
                                ! products ?
                                <Loader/>
                                :
                                <>
                                    <FontAwesomeIcon icon={ faXmark } onClick={ togglePurchaseView } className="close-product-section"/>
                                    <h4>Select a product:</h4>
                                    <div className="product-container">
                                        {
                                            products.length > 0 && products.map(( product, idx ) => {
                                                const price = product.priceInCents / 100
                                                return(
                                                    <div className="product-card round-div" key={ idx }>
                                                        <h5>{ product.name }</h5>
                                                        <p>{ product.description }</p>
                                                        <p>{ `Price: $${ price }.00` }</p>
                                                        <button className="product-purchase-btn" onClick={ ()=> handleCheckoutSession( product.id )}>Buy</button>
                                                    </div>
                                                )
                                            })

                                        }
                                    </div>
                                </>
                            }
                        </>
                        
                    }
                    </>
                }

            </div>

        </div>
    )
}