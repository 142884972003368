import FavoriteBtn from "../FavoriteBtn/FavoriteBtn"

export default function DetailHeadshotContainer({ avatar, darkMode }) {
    return(
        <div className={`headshot-container round-div div-shadow ${ !darkMode ? 'light' : '' }`}>
            <FavoriteBtn avatar={ avatar }  />
            <div className="headshot-inner-container">
                <div className="img-container">
                    <img src={ avatar.data.img } alt="avatar"/>
                </div>
                <h4>{ avatar.data.name }</h4>
            </div>
            <div className="headshot-detail-container">
                <p>Age: {avatar.data.age}</p>
                <p>Occupation: {avatar.data.occupation}</p>
            </div>
            <div className="headshot-detail-container">
                <h4>Prepared for:</h4>
                <p>Company: {avatar.data.companyName}</p>
                <p>Campaign: {avatar.data.campaignName}</p>
            </div>
        </div>
    )
}