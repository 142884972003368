
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashCan, faPenToSquare, faXmark, faCheck } from '@fortawesome/free-solid-svg-icons'
import { useState } from "react"

export default function CampaignRow({ campaign, lastItem, handleSubmitUpdate, handleDelete }) {

    //STATES
    const [ showEdit, setShowEdit ] = useState( false )
    const [ inputValue, setInputValue ] = useState( campaign.displayName )

    //FUNCTIONS
    const handleChange = ( e ) => {
        const { value } = e.target
        setInputValue( value )
    }

    const toggleEdit = () => {
        
        setShowEdit( !showEdit )
    }

    const handleSubmit = ( e ) => {

        //PREVENTS BROWSER FROM RELOADING
        e.preventDefault()

        handleSubmitUpdate( campaign.id, inputValue )
    }

    return(
        <div className={`subitem-row ${ lastItem ? 'last' : ''} `}>
            <>
            { !showEdit ?

                <p>{ campaign.displayName }</p>
                :
                <form onSubmit={ handleSubmit }>
                    <input type="text" value={ inputValue } onChange={ handleChange }/>
                </form>
            }
            </>
            <div className="btn-container">
                { !showEdit ?
                    <>
                        <div className="item-btn">
                            <FontAwesomeIcon icon={ faTrashCan } onClick={ () => handleDelete(campaign.id) }/>
                        </div>
                        <div className="item-btn" >
                            <FontAwesomeIcon icon={ faPenToSquare } onClick={ toggleEdit }/>
                        </div>
                    </>
                    :
                    <>
                        <div className="item-btn" >
                            <FontAwesomeIcon icon={ faCheck } onClick={ handleSubmit }/>
                        </div>
                        <div className="item-btn" onClick={ toggleEdit }>
                            <FontAwesomeIcon icon={ faXmark }/>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}