import { useContext } from "react"
import { AppContext } from "../../context/AppContext"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons"
import useAccounts from "../../hooks/useAccounts"

export default function SignOutBtn({ desktop }) {

    //CONTEXT
    const { sideBarExpanded, darkMode } = useContext(AppContext)

    //HOOKS
    const { handleSignOut } = useAccounts()

    return(
        <button className={`sign-out-btn round-btn btn ${!sideBarExpanded && 'centered'} ${ !darkMode ? 'light' : ''} ${ !desktop ? 'mobile' : ''} `} onClick={ handleSignOut }>
            <FontAwesomeIcon icon={ faRightFromBracket }/>
            {
                sideBarExpanded && 
                <p className={`${ sideBarExpanded ? 'full-width' : 'no-width' }`}>Sign Out</p>
            }
        </button>
    )
}