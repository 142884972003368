import { useCallback, useContext, useEffect, useState } from "react"
import { AppContext } from "../../context/AppContext"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLink } from "@fortawesome/free-solid-svg-icons"
import useAvatars from "../../hooks/useAvatars"

export default function OrderItem({ order }) {

    //STATE
    const [ orderedDate, setOrderedDate ] = useState( null )
    const [ deliveredDate, setDeliveredDate ] = useState( null )

    //CONTEXT
    const { capitalizeWords } = useContext( AppContext )

    //HOOKS
    const { getAvatarById } = useAvatars()

    //FUNCTION 
    const formatDate = useCallback( ( timestamp ) => {

        const date = new Date( timestamp )
        const options =  { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' }
        const formattedDate = date.toLocaleDateString( 'en-US', options )
        return formattedDate 

    }, [ ] )

    const getAvatar = async () => {
        try{
            const docId = order.docId
            await getAvatarById( docId )
             
        } catch ( err ) {
            throw err
        }
    }

    //EFFECTS
    useEffect(() => {
        
        const ordered = formatDate( order.ordered )
        setOrderedDate( ordered )

        if ( order.delivered ) {
            const delivered = formatDate( order.delivered )
            setDeliveredDate( delivered )
        }

    }, [ order, formatDate ])

 
    return(
        <li>
            <p className="item l">{ `${capitalizeWords( order.company )} - ${capitalizeWords( order.campaign )} `}</p>
            <p className="item m">{ orderedDate }</p>
            {/* <p className="item m">{ deliveredDate ?  deliveredDate : '-'}</p> */}
            <div className="item s">
                <span className={ order.status.toLowerCase() === 'complete' ? 'complete' : '' } ></span>
                <p>{ order.status }</p>
            </div>
            <div className="item xs">
                { order.docId ?
                <Link to={`/avatarDetail/${ order.docId }`} onClick={ getAvatar } >
                    <FontAwesomeIcon icon={ faLink }/>
                </Link>
                :
                <p>-</p>
                }

            </div>
        </li>
    )
}