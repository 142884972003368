import { useContext } from "react"
import { NavLink } from "react-router-dom"
import { AppContext } from "../../context/AppContext"
import { faHouse, faStar, faGear, faUsers, faCirclePlus, faBuilding, faComment, faGears, faList, faTerminal } from "@fortawesome/free-solid-svg-icons"
import SideBarBtn from "../SideBarBtn/SideBarBtn"
import SignOutBtn from "../SignOutBtn/SignOutBtn"
import LogoContainer from "../LogoContainer/LogoContainer"
import DarkToggleBtn from "../DarkToggleBtn/DarkToggleBtn"


export default function SideBar() {

    //CONTEXT
    const { sideBarExpanded, globalUser } = useContext(AppContext)
    return(
        <nav className={`nav-container-desktop`}> 
            <ul>
                <li>
                    <LogoContainer desktop={ true }/>
                </li>
                <SideBarBtn icon={ faCirclePlus } text={'Create'} route={'/create'} desktop={ true }/>
                <SideBarBtn icon={ faHouse } text={'Avatar Library'} route={'/'} desktop={ true }/>
                <SideBarBtn icon={ faTerminal } text={'Prompt Library'} route={'/prompts'} desktop={ true }/>
                <SideBarBtn icon={ faList } text={'Orders'} route={'/orders'} desktop={ true }/>
                <SideBarBtn icon={ faUsers } text={'Community'} route={'/community'} desktop={ true }/>
                <SideBarBtn icon={ faComment } text={'Chat'} route={'/chat'} desktop={ true }/>
                {globalUser?.isAdmin && <SideBarBtn icon={ faGear } text={'Admin Panel'} route={'/admin'} desktop={ true }/> }
                <div className="botom-options">
                    <DarkToggleBtn sideBarExpanded={ sideBarExpanded } desktop={ true }/>
                    <SideBarBtn icon={ faGears } text={'Settings'} route={'/settings'} desktop={ true }/>
                    <SignOutBtn desktop={ true }/>
                </div>
            </ul>
        </nav>
        
    )
}