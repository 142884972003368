import { useCallback } from "react";
import axios from "axios";

function useProducts( ) {

    const getAllProducts = useCallback( async ( ) => {

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/products/all`)
            const products = res.data
            return products

        } catch ( err ) {
            throw err
        }

    }, [ ])

    const createCheckoutSession = async ( accountId, productId ) => {
        try {
            const order = {
                accountId, 
                productId
            }

            //CREATE CHECKOUT SESSION
            const sessionUrl = await axios.post(`${process.env.REACT_APP_API_URL}/products/checkout`, order, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })

            return sessionUrl.data
            
        } catch ( error ) {
            console.log(error);
            throw error
        }
    }

    return{
        getAllProducts,
        createCheckoutSession
    }
}

export default useProducts


