import CreateCard from "../CreateCard/CreateCard"

export default function CreateCardsContainer({ handleSelectedProduct }) {

    //PRODUCTS
    const products = {
        blueprint: {
            title: 'Custom Blueprint',
            description: 'Get Inside The Mind Of Your Market:',
            features: [
                `Master Buyer's Blueprint`,
                'Language & Messaging',
                'Real Human Support'
            ],
            priceInCredits: 1,
            delivery: '2 business days.',
            enabled: true,
            collectionName: 'blueprintOrders'
        },
        brief:{
            title: 'Buyers Brief',
            description: 'Accelerate Your Go To Market Strategy',
            features: [
                'Everything in Blueprint',
                'Segmentation Deep Dive',
                'Strategy & Implementation Guide'
            ],
            priceInCredits: 10,
            delivery: '2 business days.',
            enabled: false,
            collectionName: 'blueprintOrders'
        }
    }


    return(
        <div className="create-cards-container">
            <CreateCard handleSelectedProduct={ handleSelectedProduct } product={ products.blueprint }/> 
            <CreateCard handleSelectedProduct={ handleSelectedProduct } product={ products.brief }/> 
        </div>
    )
}