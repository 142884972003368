import { useEffect, useState } from "react"
import Swal from "sweetalert2"

export default function LoginForm({ isLogging, handleIsLogging, handleSubmit, handleChange, formData }) {

    const [ buttonEnabled, setButtonEnabled] = useState( false )
    const [ stage, setStage ] = useState( 1 )

    const handleContinue = () => {
        if( formData.password === formData.checkPassword ){
            setStage(2)
        } else {
            Swal.fire({
                title: 'Oops!',
                text: 'Your passwords do not match',
                icon: 'warning',
                confirmButtonText: 'Ok',
                buttonsStyling: false,
                customClass: {
                    popup: 'marketsauce-alert-container round-div div-shadow',
                    icon: 'alert-icon',
                    confirmButton: 'confirm-btn btn order2',
                    denyButton: 'deny-btn btn order1',
                }
            })
        }

    }

    //EFFECTS
    useEffect(() => {
        if ( isLogging && formData.email !== '' && formData.password !== '') {
            setButtonEnabled( true )
        } else if ( stage === 1 && !isLogging && formData.email !== '' && formData.password !== '' && formData.checkPassword !== '' ) {
            setButtonEnabled( true )
        } else if ( stage === 2 && !isLogging && formData.name !== '' && formData.lastname !== '' && formData.company !== ''){
            setButtonEnabled( true )
        }
        else {
            setButtonEnabled( false )
        }
    }, [ isLogging, formData, stage ])

    useEffect(() => {
        setStage(1)
    }, [ isLogging ])

    return(
        <>
        <div className="img-container">
            <img src="/images/logo_full_dark.png" alt="marketsauce"/>
        </div>
        <h3>{ isLogging ? 'Welcome' : 'Register' }</h3>
        <form className="login-form" onSubmit={ handleSubmit }>
            {
                stage === 1 ?
                <>
                    <label>
                        Email:
                        <input type="email" name="email" placeholder="your@email.com" autoComplete="on" className="round-btn" onChange={ handleChange } value={ formData.email }/>
                    </label>
                    <label>
                        Password:
                        <input type="password" name="password" placeholder="******" autoComplete="off" className="round-btn" onChange={ handleChange } value={ formData.password }/>
                    </label>
                    {
                        !isLogging &&
                        <label>
                            Repeat password:
                            <input type="password" name="checkPassword" placeholder="******" autoComplete="off" className="round-btn" onChange={ handleChange } value={ formData.checkPassword }/>
                        </label>
                    }
                </>
                :
                <>
                    <label>
                        First Name:
                        <input type="text" name="name" placeholder="John" autoComplete="on" className="round-btn" onChange={ handleChange } value={ formData.name }/>
                    </label>
                    <label>
                        Last Name:
                        <input type="text" name="lastname" placeholder="Doe" autoComplete="on" className="round-btn" onChange={ handleChange } value={ formData.lastname }/>
                    </label>
                    <label>
                        Company:
                        <input type="text" name="company" placeholder="Acme Inc" autoComplete="on" className="round-btn" onChange={ handleChange } value={ formData.company }/>
                    </label>
                </>
            }
            <div className="login-footer">
                <p>{ isLogging ? 'Need an account?' : 'Already have an account?' }</p>
                <span onClick={ () => { handleIsLogging( !isLogging ) }}>{ isLogging ? 'Sign Up' : 'Log In' }</span>
            </div>
            {   isLogging ?
                <button className={`btn login-btn ${ !buttonEnabled ? 'disabled': '' }`} disabled={ buttonEnabled ? false : true } onClick={ handleSubmit }>{ 'Log In' }</button>
                :
                <>
                    {
                        stage === 2 &&
                        <button className={`btn login-btn ${ !buttonEnabled ? 'disabled': '' }`} disabled={ buttonEnabled ? false : true } onClick={ handleSubmit }>{  'Sign Up' }</button>
                    }
                </>
            }
        </form>
        {
            !isLogging && stage === 1 &&
            <button className={`btn login-btn ${ !buttonEnabled ? 'disabled': '' }`} disabled={ buttonEnabled ? false : true } onClick={ handleContinue }>{ 'Continue'}</button>
        }
        <div className="login-form-divider">
            <span></span>
            <p>or</p>
            <span></span>
        </div>
        </>
    )
}