import { NavLink } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

export default function LogoContainer({ desktop }) {

    const { sideBarExpanded, darkMode } = useContext( AppContext )

    return(
        <NavLink to='/'>
            <div className={`nav-logo-container ${ desktop ? 'desktop' : ''}`}>
                <>
                {sideBarExpanded ?
                    <img src={`/images/logo_full_${ darkMode ? 'dark' : 'light' }.png`} alt="marketsauce"/>
                    :
                    <img src={`/images/logo_trim_${ darkMode ? 'dark' : 'light' }.png`} alt="marketsauce"/>
                }
                </>
            </div>
        </NavLink>
    )

}