import InviteContainer from "../components/InviteContainer/InviteContainer";
import Footer from '../components/Footer/Footer'

export default function Invite() {

    return(
        <>
            <InviteContainer/>
            <Footer/>
        </>
    )
}