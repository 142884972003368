import { useEffect, useContext, useState } from 'react'
import { AppContext } from '../context/AppContext'
import SideBarContainer from '../components/SideBarContainer/SideBarContainer'
import BoardContainer from '../components/BoardContainer/BoardContainer'
import AvatarCardsContainer from '../components/AvatarCardsContainer/AvatarCardsContainer'
import Login from './Login'
import GlobalLoader from '../components/GlobalLoader/GlobalLoader'
import TopBarContainer from '../components/TopBarContainer/TopBarContainer'
import AccountInactiveMessage from '../components/AccountInactiveMessage/AccountInactiveMessage'
import Footer from '../components/Footer/Footer'
import useAvatars from '../hooks/useAvatars'
import Swal from 'sweetalert2'

export default function Library() {

    //STATE
    const [ isLoading, setIsLoading ] = useState(true)

    //CONTEXT
    const { userAvatars, globalLoader, globalAccount, globalUser } = useContext( AppContext )
    
    //HOOKS
    const { updateAvatarName, updateAvatars } = useAvatars()

    //FUNCTIONS
    const handleNameUpdate = async ( id, name ) => {
        if( name !== ''){
            setIsLoading( true )
            const accountId = globalAccount.id
            await updateAvatarName( id, name )
            await updateAvatars( accountId )
            setIsLoading( false )

        } else {
            Swal.fire({
                title: 'Oops!',
                text: 'Please assign a new name for your Avatar',
                icon: 'warning',
                confirmButtonText: 'Ok',
                buttonsStyling: false,
                customClass: {
                    popup: 'marketsauce-alert-container round-div div-shadow',
                    icon: 'alert-icon',
                    confirmButton: 'confirm-btn btn order2',
                    denyButton: 'deny-btn btn order1',
                }
            })
        }
    }

    //EFFECTS
    useEffect(() => {

        //LOADER IS TURNED OFF AFTER userAvatars IS RECEIVED, WHEATHER IT IS AN EMPTY ARRAY OR NOT.
        if( userAvatars ){
            setIsLoading( false )
        }
    }, [ userAvatars ])


    return(
        <>
        { globalLoader ?
            <GlobalLoader/>
            :
            <>
            { !globalUser ?
                <Login/>
                :
                <>
                    <div className={`view-container`}>
                        <SideBarContainer/>
                        <TopBarContainer/>
                        <BoardContainer sectionTitle={ `Avatar Library` } faded={ true } section={ globalAccount && !globalAccount.isActive ?
                        <AccountInactiveMessage/> : <AvatarCardsContainer handleNameUpdate={ handleNameUpdate } cardsArray={ userAvatars } isLoading={ isLoading }/>} />
                    </div>
                    <Footer/>
                </>
            }
            </>
        }
        </>
    )
}