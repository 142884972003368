import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { Link } from "react-router-dom";
import Footer from "../components/Footer/Footer";


export default function NotFound () {

    const { darkMode } = useContext( AppContext )

    return (
        <>
        <div className={`view-container login ${ !darkMode ? 'light' : ''}`}>
            <div className={`no-data-message ${ !darkMode ? 'light' : ''}`}>
                <h3>Oops!</h3>
                <p>This route was not found</p>
                <div className="view-cta-container">
                <Link to={'/'}>
                    <button className={`btn round-btn view-cta ${ !darkMode ? 'light' : ''}`}>
                        Back to Home 
                    </button>
                </Link>
                </div>
            </div>
        </div>
        <Footer/>
        </>
    )
}