import { useEffect, useContext, useState } from 'react'
import { AppContext } from '../context/AppContext'
import SideBarContainer from '../components/SideBarContainer/SideBarContainer'
import BoardContainer from '../components/BoardContainer/BoardContainer'
import Login from './Login'
import GlobalLoader from '../components/GlobalLoader/GlobalLoader'
import TopBarContainer from '../components/TopBarContainer/TopBarContainer'
import AccountInactiveMessage from '../components/AccountInactiveMessage/AccountInactiveMessage'
import OrdersContainer from '../components/OrdersContainer/OrdersContainer'

export default function Orders() {

    //STATE
    const [ isLoading, setIsLoading ] = useState(true)

    //CONTEXT
    const { userAvatars, globalUser, globalLoader, globalAccount, authToken } = useContext( AppContext )

    //EFFECTS
    useEffect(() => {

        //LOADER IS TURNED OFF AFTER userAvatars IS RECEIVED, WHEATHER IT IS AN EMPTY ARRAY OR NOT.
        if(userAvatars){
            setIsLoading(false)
        }

    }, [userAvatars])

    return(
        <>
        { globalLoader ?
            <GlobalLoader/>
            :
            <>
            { !authToken ?
                <Login/>
                :
                <div className={`view-container`}>
                    <SideBarContainer/>
                    <TopBarContainer/>
                    <BoardContainer sectionTitle={ `Orders` } faded={ false } section={ globalAccount && !globalAccount.isActive ?
                    <AccountInactiveMessage/> : <OrdersContainer/> } />
                </div>
            }
            </>
        }
        </>
    )
}