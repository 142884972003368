import { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";


export default function Success () {
    //STATE
    const [ counter, setCounter ] = useState( 5 )

    //CONTEXT
    const { darkMode } = useContext( AppContext )

    //ROUTER
    const navigate = useNavigate()

    //FUNCTION
    const countDown = useCallback( () => {
        setCounter( counter - 1 )
    }, [ counter ] )

    //EFFECTS
    useEffect(() => {
        if ( counter > 0 ){
            setTimeout(() => {
                countDown()
            }, 1000 )
        } else if ( counter === 0 ){
            navigate('/')
        }
    }, [ counter, countDown, navigate ] )

    return (
        <>
            <div className={`view-container success ${ !darkMode ? 'light' : ''}`}>
                <div className={`no-data-message ${ !darkMode ? 'light' : ''}`}>
                    <div className="success-payment-icon-container">
                        <FontAwesomeIcon icon={ faCheckCircle }/>
                    </div>
                    <h3>Payment successful!</h3>
                    <p>{`You will be directed to your Home Screen in ${ counter } seconds`}</p>
                    <div className="view-cta-container">
                    <Link to={'/'}>
                        <button className={`btn round-btn view-cta ${ !darkMode ? 'light' : ''}`}>
                            Return to Home 
                        </button>
                    </Link>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}