export default function ChatMessage ({ message }) {
    return(
        <div className={`message-container ${ message.role === 'user' ? 'sent' : '' }`}>
            <div className="inner-div">
                { message.sent ?
                    <p>{ message.content }</p>
                    :
                    <div dangerouslySetInnerHTML={{__html: message.content}}></div>
                }
            </div>
        </div>
    )
}