import { useContext, useState } from "react";
import BlueprintFormContainer from "../BlueprintFormContainer/BlueprintFormContainer";
import FormProgressBar from "../FormProgressBar/FormProgressBar";
import CreateCardsContainer from "../CreateCardsContainer/CreateCardsContainer";
import CreateUserInfo from "../CreateUserInfo/CreateUserInfo";
import BackBtn from '../BackBtn/BackBtn'
import { AppContext } from "../../context/AppContext";
import Swal from "sweetalert2";

export default function CreateContainer() {

    //INITIAL VALUES
    const initalFormState = {
        companyName: '',
        campaignName: '',
        product: '',
        context: '',
    }

    //STATE
    const [ progress, setProgress ] = useState( 1 )
    const [ selectedProduct, setSelectedProduct ] = useState( null )
    const [ formData, setFormData ] = useState( initalFormState )
    const [ selectedFile, setSelectedFile ] = useState( null )

    //CONTEXT
    const { globalAccount, darkMode } = useContext( AppContext )

    //FUNCTIONS
    const handleChange = ( e ) => {

        //DESTRUCTURES event FROM INPUT
        const { name, value } = e.target

        //UPDATES formData STATE
        setFormData( prevFormData => ({
            ...prevFormData,
            [ name ]: value
        }))
    }

    const handleCompany = ( company ) => {

        //UPDATES formData STATE
        setFormData( prevFormData => ({
            ...prevFormData,
            companyName: company.displayName,
            companyId: company.id,
        }))
    }

    const handleCampaign = ( campaign ) => {
        
        //UPDATES formData STATE
        setFormData( prevFormData => ({
            ...prevFormData,
            campaignName: campaign.displayName,
            campaignId: campaign.id,
        }))
    }


    const handleSelectedFile = ( file ) => {

        setSelectedFile( file )
    }


    const handleProgress = ( n ) => {

        //UPDATES PROGRESS∑
        setProgress( progress + n )
    }

    const handleSelectedProduct = ( product ) => {

        //CHECKS IF USER HAS CREDITS FOR THIS TRANSACTION
        if( userHasCredits( product.priceInCredits ) ) {
            //IF ENOUGH CREDITS, SETS SELECTED PRODUCT TO OPEN FORM
            setSelectedProduct( product )

        } else {
            Swal.fire({
                title: 'Oops!',
                text: 'Insufficient credits',
                icon: 'warning',
                timer: 2500,
                confirmButtonText: 'Ok',
                buttonsStyling: false,
                customClass: {
                    popup: 'marketsauce-alert-container round-div div-shadow',
                    icon: 'alert-icon',
                    confirmButton: 'confirm-btn btn order2',
                    denyButton: 'deny-btn btn order1',
                }
            })
        }
    }

    const handleBack = () => {

        //DELETES selectedProduct FROM STATE
        setSelectedProduct( false )

        //RESETS FORM DATA
        setFormData( initalFormState )
        setSelectedFile( null )

        //RESETS PROGRESS
        setProgress( 1 )


    }

    const userHasCredits = ( productPrice ) => {

        //CHECKS IF USER HAS CREDITS FOR THIS PURCHASE
        if ( globalAccount.credits >= productPrice ) return true
        else return false
    }  

    return(
        <div className={`create main-container ${ !darkMode ? 'light' : ''}`}>
            {
                ! selectedProduct ?
                    <>
                        <CreateUserInfo/>
                        <CreateCardsContainer handleSelectedProduct={ handleSelectedProduct } selectedProduct={ selectedProduct }/>
                    </>
                :   
                    <div className="form-main-container">
                        <FormProgressBar progress={ progress } darkMode={ darkMode }/>
                        <BlueprintFormContainer progress={ progress } handleProgress={ handleProgress }  userHasCredits={ userHasCredits } selectedProduct={ selectedProduct } handleChange={ handleChange } formData={ formData } handleCompany={ handleCompany } handleCampaign={ handleCampaign } handleSelectedFile={handleSelectedFile} selectedFile={ selectedFile }/>
                        <BackBtn action={ handleBack }/>
                    </div>
            }
        </div>
    )
}