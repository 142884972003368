import { faBuilding, faCreditCard, faUsd, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export default function SettingsContainer() {

    return (
        <div className={`settings main-container`}>
            <div className="inner-container round-div div-shadow">
                <div className="settings-button-container">
                    <Link to={'/settings/team'}>
                        <div className="settings-button round-div div-shadow">
                            <FontAwesomeIcon icon={ faUserPlus }/>
                            <h4>Team</h4>
                            <p>Manage account users</p>
                        </div>
                    </Link>
                </div>
                <div className="settings-button-container">
                    <Link to={'/settings/companies'}>
                        <div className="settings-button round-div div-shadow">
                            <FontAwesomeIcon icon={ faBuilding }/>
                            <h4>Companies</h4>
                            <p>Manage companies and campaigns</p>
                        </div>
                    </Link>
                </div>
                <div className="settings-button-container">
                    <Link to={'/settings/credits'}>
                        <div className="settings-button round-div div-shadow">
                            <FontAwesomeIcon icon={ faUsd }/>
                            <h4>Credits</h4>
                            <p>View and purchase credits</p>
                        </div>
                    </Link>
                </div>
                <div className="settings-button-container">
                    <Link to={'/settings/membership'}>
                        <div className="settings-button round-div div-shadow">
                            <FontAwesomeIcon icon={ faCreditCard }/>
                            <h4>Membership</h4>
                            <p>Manage your subscription</p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}