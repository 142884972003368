import SectionTitle from "../SectionTitle/SectionTitle"
import BottomFader from "../BottomFader/BottomFader"
import { useContext } from "react"
import { AppContext } from "../../context/AppContext"

export default function BoardContainer({ faded, sectionTitle, section }) {

    //CONTEXT
    const { darkMode } = useContext( AppContext )
    
    return(
        <div className={`view-main-container ${ !darkMode ? 'light': ''}`}>
            <SectionTitle title={ sectionTitle } darkMode={ darkMode } />
            <div className={`view-inner-container div-shadow ${faded && 'faded'} ${ !darkMode ? 'light' : ''}`}>
                {section}
            </div>
            {faded && <BottomFader darkMode={ darkMode } />}
        </div>
    )
}