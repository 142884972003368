import { useNavigate, useParams } from "react-router-dom";
import BackBtn from "../BackBtn/BackBtn";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import Loader from "../Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faMicrophone, faPaperclip, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import ChatMessage from "../ChatMessage/ChatMessage";
import useChats from "../../hooks/useChats";
import useAvatars from "../../hooks/useAvatars";
import Swal from "sweetalert2";

export default function AvatarChatContainer() {

    
    //STATE
    const [ currentAvatar, setCurrentAvatar ] = useState( null ) 
    const [ inputValue, setInputValue ] = useState( '' )
    const [ messages, setMessages ] = useState( [] )
    const [ chatLoader, setChatLoader ] = useState( false )
    const [ avatarThreads, setAvatarThreads ] = useState( null )
    const [ isLoading, setIsLoading ] = useState( true )
    const [ currentThread, setCurrentThread ] = useState( null )

    const [ selectedFile, setSelectedFile ] = useState( null ) 

    
    //CONTEXT
    const {  capitalizeWords, darkMode, globalAccount } = useContext( AppContext )

    //HOOKS
    const { getAvatarThreads, createThread, sendMessage } = useChats()
    const { getAvatarById } = useAvatars()

    //ROUTER
    const navigate = useNavigate()
    const { id } = useParams()

    //FUNCTIONS
    const goBack = () => {
        navigate(`/avatarDetail/${ id }`)
    }

    const handleSelectedFile = ( file ) => {

        setSelectedFile( file )
    }

    const handleChange = ( e ) => {

        const value = e.target.value
        setInputValue( value )

    }

    const submitMessage = async ( e ) => {

        e.preventDefault()
        
        if ( inputValue !== ''){

            setChatLoader( true )
            const message = inputValue
            const outgoingMessage = {
                createdAt: Date.now(),
                role: 'user',
                content: message
            }
            setMessages( prev => [ outgoingMessage, ...prev ])
            setInputValue( '' )
            if( !currentThread ){
                const accountId = globalAccount.id
                const avatarId = currentAvatar.id
                const thread = await createThread( accountId, avatarId )
                setCurrentThread( thread )
                const threadId = thread.id
                const response = await sendMessage( message, threadId )
                const assistanteMessage = response[ 0 ]
                const incomingMessage = {
                    content: assistanteMessage.content[ 0 ].text.value,
                    role: 'assistant',
                    createdAt: assistanteMessage.created_at
                }
                setMessages( prev => [ incomingMessage, ...prev ])
            } else {
                const response = await sendMessage( message, currentThread.id )
                const assistanteMessage = response[ 0 ]
                const incomingMessage = {
                    content: assistanteMessage.content[ 0 ].text.value,
                    role: 'assistant',
                    createdAt: assistanteMessage.created_at
                }
                setMessages( prev => [ incomingMessage, ...prev ])
            }
            
            setChatLoader( false )
        }
    }

    const handleNewThread = () => {
        setCurrentThread( null )
        setInputValue('')
        setMessages([])
    }



    //EFFECTS
    useEffect(() => {

        const getThreads = async ( id ) => {
            try {

                const threads = await getAvatarThreads( id )
                threads.sort(( a, b ) => b.updatedAt - a.updatedAt ) 
                setAvatarThreads( threads )

                const avatar = await getAvatarById( id )
                setCurrentAvatar( avatar )
                setIsLoading( false )

            } catch ( err ) {
                Swal.fire({
                    title: 'Oops!',
                    text: err.message,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    buttonsStyling: false,
                    customClass: {
                        popup: 'marketsauce-alert-container round-div div-shadow',
                        icon: 'alert-icon',
                        confirmButton: 'confirm-btn btn order2',
                        denyButton: 'deny-btn btn order1',
                    }
                })
            }
        }
        if( id ) {

            getThreads( id )

        }

    }, [ id, getAvatarThreads, getAvatarById ] )

    return(
        <>
            {
                isLoading ? 
                <Loader/>
                :
                <div className={`chat main-container ${ !darkMode ? 'light' : ''}`}>
                    <BackBtn action={ goBack }/>
                    <div className={`chat-sidebar-container`}>
                        <div className="threads-list-header" >
                            <p>Threads</p>
                            <FontAwesomeIcon icon={ faPlusCircle } onClick={ handleNewThread } className={'new-thread-btn'}/>
                        </div>
                        <div className="thread-list">
                            {
                                avatarThreads.map(( thread, idx ) => {
                                    return(
                                        <p key={ idx }> {thread.name} </p>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <span></span>
                    <div className={`chat-inner-container`}>
                        <div className="chat-header-container desktop">
                            <div className="inner">
                                <p>{`Conversation with ${capitalizeWords( currentAvatar.data.name )}  `}</p>
                            </div>
                        </div>
                        <div className="chat-thread-container">
                            <div className="chat-container">
                                { chatLoader && <span className="chat-loader"></span>}
                                { messages && 
                                    messages.map(( message, idx) => {
                                        return(
                                            <ChatMessage message={ message } key={ idx }/>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="chat-input-container">
                            <label htmlFor="file-input" style={{ cursor: 'pointer' }} className="attachments">
                                <FontAwesomeIcon icon={ faPaperclip }/>
                            </label>
                            <input type="file"  id="file-input" style={{ display: 'none' }} accept='.txt, .rtf, .doc, .docx, .odt, text/plain, application/msword' onChange={ ( e ) => handleSelectedFile( e.target.files[0] )}/>
                            <form onSubmit={ submitMessage }>
                                <input type="text" onChange={ handleChange } value={ inputValue }/>
                            </form>
                            <button onClick={ submitMessage } className={`submit ${ inputValue !== '' ? 'filled' : '' }`}>
                                <FontAwesomeIcon icon={ faArrowUp }/>
                            </button>
                            <button className="record">
                                <FontAwesomeIcon icon={ faMicrophone }/>
                            </button>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}