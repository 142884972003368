import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons'
import { faStar as borderStar} from '@fortawesome/fontawesome-free-regular'
import useAvatars from "../../hooks/useAvatars"

export default function FavoriteBtn({ avatar }) {

    //HOOKS
    const { toggleFavorite } = useAvatars()

    return(
        <div className={`favorite-icon-container ${avatar.data.favorite && 'favorite'}`} onClick={() => toggleFavorite( avatar ) }>
            <FontAwesomeIcon icon={ avatar.data.favorite ? solidStar : borderStar}/>
        </div>
    )
}