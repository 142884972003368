import { useCallback, useContext, useEffect, useState } from 'react'
import { AppContext } from "../context/AppContext";
import { v4 as uuidv4 } from 'uuid'
import Swal from 'sweetalert2';
import axios from 'axios'

function useCompanies(  ){

    //STATES
    const [ accountCompanies, setAccountCompanies ] = useState( null )
    
    //CONTEXT
    const { globalUser, capitalizeWords, authToken } = useContext( AppContext )

    //FUNCTIONS
    const createCompany = async ( companyName ) => {
        
        try{
            
            //GETS ACCOUNT ID FROM GLOBAL USER
            const accountId = globalUser.account
            //CREATES ID FOR COMPANY
            const id = uuidv4()
            
            const company = {
                accountId: accountId,
                displayName: capitalizeWords( companyName ),
                id: id,
                campaigns: true,
                timestamp: Date.now()
            }

            //CREATES COMPANY IN DB
            await axios.post(`${process.env.REACT_APP_API_URL}/companies/`, company, {
                headers: {
                    'Content-Type': 'application/json',
                     'Authorization': `Bearer ${ authToken }`
                }
            })

            //UPDATES ACCOUNT COMPANIES
            getAccountCompanies()

            //SHOOTS ALERT
            Swal.fire({
                // title: 'Welcome',
                text: 'Company Created! Please create a campaign for this Company.',
                icon: 'success',   
                buttonsStyling: false,
                showConfirmButton: true,
                customClass: {
                    popup: 'marketsauce-alert-container round-div div-shadow',
                    icon: 'alert-icon',
                    confirmButton: 'confirm-btn btn order2',
                    denyButton: 'deny-btn btn order1',
                }
            })

        } catch ( err ) {
            //SHOWS ALERT
            Swal.fire({
                title: 'Oops!',
                text: err.message,
                icon: 'warning',
                confirmButtonText: 'Ok',
                buttonsStyling: false,
                customClass: {
                    popup: 'marketsauce-alert-container round-div div-shadow',
                    icon: 'alert-icon',
                    confirmButton: 'confirm-btn btn order2',
                    denyButton: 'deny-btn btn order1',
                }
            })
        }  
    }

    const createCampaign = async ( campaign ) => {

        try{
            const id = uuidv4()   
            const campaignObj = {
                id: id,
                ...campaign
            }
            //CREATES CAMPAIGN IN DB
            await axios.post(`${process.env.REACT_APP_API_URL}/companies/campaigns`, campaignObj, {
                headers: {
                    'Content-Type': 'application/json',
                     'Authorization': `Bearer ${ authToken }`
                }
            })

        } catch ( err ) {

            //SHOWS ALERT
            Swal.fire({
                title: 'Oops!',
                text: err.message,
                icon: 'warning',
                confirmButtonText: 'Ok',
                buttonsStyling: false,
                customClass: {
                    popup: 'marketsauce-alert-container round-div div-shadow',
                    icon: 'alert-icon',
                    confirmButton: 'confirm-btn btn order2',
                    denyButton: 'deny-btn btn order1',
                }
            })
        }
    }

    const updateCompanyById = async ( companyId, updatedName ) => {

        try{

            const updatedData = { displayName: updatedName }

            //UPDATES ACCOUNT CREDITS AFTER PURCHASE   
            await axios.patch(`${ process.env.REACT_APP_API_URL }/companies/${ companyId }`, updatedData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${ authToken }`
                }
            })    

            getAccountCompanies()

        } catch ( err ) {
            console.log ( err )
        }

    } 

    const updateCampaignById = async ( campaignId, updatedName ) => {

        try{

            const updatedData = { displayName: updatedName }

            //UPDATES ACCOUNT CREDITS AFTER PURCHASE   
            await axios.patch(`${ process.env.REACT_APP_API_URL }/companies/campaigns/${ campaignId }`, updatedData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${ authToken }`
                }
            })    

        } catch ( err ) {
            console.log ( err )
        }

    } 

    const deleteCompanyById = async ( companyId ) => {

        try{
            //DELETES COMPANY FROM DB   
            await axios.delete(`${process.env.REACT_APP_API_URL}/companies/${ companyId }`, {
                headers: {
                    'Authorization': `Bearer ${ authToken }`
                }
            })    

            //UPDATES ACCOUNT COMPANIES
            getAccountCompanies()

        } catch ( err ) {
            console.log(err);
        }
    } 


    const deleteCampaignById = async ( campaignId ) => {

        try{
            //DELETES COMPANY FROM DB   
            await axios.delete(`${process.env.REACT_APP_API_URL}/companies/campaigns/${ campaignId }`, {
                headers: {
                    'Authorization': `Bearer ${ authToken }`
                }
            })    
        } catch ( err ) {
            console.log(err);
        }
    } 

    const getAccountCompanies = useCallback( async () => {

        try{
            //GETS ACCOUNT ID FROM GLOBAL USER
            const accountId = globalUser.account 

            const res = await axios.get(`${process.env.REACT_APP_API_URL}/companies/account/${ accountId }`, {
                headers: { 
                    Authorization: `Bearer ${ authToken }`
                }
            })
            const companiesArray = res.data
            setAccountCompanies( companiesArray )
            return companiesArray
    
        } catch ( err ) {

            console.log(err);
            setAccountCompanies( [] )
            return []
        }

    }, [ authToken, globalUser ] ) 

    const getCompanyCampaigns = useCallback( async ( companyId ) => {

        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/companies/${ companyId }/campaigns`, {
                headers: { 
                    Authorization: `Bearer ${ authToken }`
                }
            })
            const campaignsArray = res.data
            return campaignsArray

        } catch ( err ) {

            console.log(err);
            return []
        }
    }, [ authToken ])

    const getCompanyById = async ( companyId ) => {

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/companies/${ companyId }`, {
                headers: { 
                    Authorization: `Bearer ${ authToken }`
                }
            })
            const company = res.data
            return company

        } catch (err) {
            console.error(err);
            return null 
        }
    }

    const getCampaignById = async ( campaignId ) => {
        
        try {

            const res = await axios.get(`${process.env.REACT_APP_API_URL}/companies/campaigns/${ campaignId }`, {
                headers: { 
                    Authorization: `Bearer ${ authToken }`
                }
            })
            const campaign = res.data
            return campaign

        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {

        getAccountCompanies()

    }, [ getAccountCompanies ])

    return{
        accountCompanies,
        getCompanyCampaigns,
        getAccountCompanies,
        deleteCampaignById,
        updateCampaignById,
        updateCompanyById,
        createCampaign,
        createCompany,
        deleteCompanyById,
        getCompanyById,
        getCampaignById
    }
}

export default useCompanies