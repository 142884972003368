import { useContext, useState } from "react"
import { AppContext } from "../../context/AppContext"
import Swal from "sweetalert2"

export default function OnboardingForm({ createUser, googleUser, handleLoader }) {

    //STATE
    const [ companyName, setCompanyName ] = useState( '' )
  
    //CONTEXT
    const { setPopulateProfile } = useContext( AppContext )  

    //FUNCTIONS
    const handleChange = ( e ) => {

        //DESTRUCTURES event FROM INPUT
        const { value } = e.target
        //UPDATES company STATE
        setCompanyName( value )
    }

    const handleSubmit = async ( e ) => {
        
        //PREVENTS DEFAULT BROWNSER RELOAD
        e.preventDefault()

        //TURNS LOADER ON
        handleLoader( true )

        const data = {
            ...googleUser,
            isAdmin: false,
            credits: 0,
            company: companyName
        }

        try {
            await createUser( data, true )
            setPopulateProfile( true )
        } catch ( err ) {

            Swal.fire({
                title: 'Oops!',
                text: err.message,
                icon: 'warning',
                confirmButtonText: 'Ok',
                buttonsStyling: false,
                customClass: {
                    popup: 'marketsauce-alert-container round-div div-shadow',
                    icon: 'alert-icon',
                    confirmButton: 'confirm-btn btn order2',
                    denyButton: 'deny-btn btn order1',
                }
            })
        }
    }


    return(
        <>
            <form className="login-form" onSubmit={ handleSubmit }> 
                <label>
                    Company:
                    <input type="text" name="name" placeholder="John" autoComplete="on" className="round-btn" onChange={ handleChange } value={ companyName.company }/>
                </label>
            </form>
            <button className="btn login-btn " onClick={ handleSubmit }>Submit</button>
        </>
    )
}