import { useContext } from 'react'
import { AppContext } from '../context/AppContext'
import SideBarContainer from '../components/SideBarContainer/SideBarContainer'
import BoardContainer from '../components/BoardContainer/BoardContainer'
import AvatarDetailContainer from '../components/AvatarDetailContainer/AvatarDetailContainer'
import Login from './Login'
import GlobalLoader from '../components/GlobalLoader/GlobalLoader'
import TopBarContainer from '../components/TopBarContainer/TopBarContainer'


export default function AvatarDetail () {

    //CONTEXT
    const { globalUser, globalLoader } = useContext(AppContext)
    
    return(
        <>
        { globalLoader ?
            <GlobalLoader/>
            :
            <>
            {!globalUser ?
                <Login/>
                :
                <div className='view-container'>
                    <SideBarContainer/>
                    <TopBarContainer/>
                    <BoardContainer sectionTitle={ 'Avatar Detail' } faded={ true } section={ <AvatarDetailContainer/>}/>
                </div>
            }
            </>
        }
        </>
    )
}