import { Link } from "react-router-dom";
import CreateBtnView from "../CreateBtnView/CreateBtnView";

export default function NoDataMessage({ darkMode }) {

    //VARIABLES
    const voidMessage = {
        title: 'Oops! There are no Avatars to display.',
        text: `If you are new to our App, you can check the Onboarding Guide.`,
        ctaText: `If you have not created your Buyer's Brief or Blueprint, you can order your first one here:`,
        create: true
    }

    return(
        <div className={`no-data-message ${ !darkMode ? 'light' : ''}`}>
            <h3>{ voidMessage.title }</h3>
            <p>{ voidMessage.text }</p>
            {voidMessage.create && 
                <div className="view-cta-container">
                    {/* <p>{ voidMessage.ctaText }</p> */}
                    {/* <Link to={'/onboarding'}>
                        <button className={`btn round-btn view-cta ${ !darkMode ? 'light' : ''}`}>
                            Go to Onboarding Guide 
                        </button>
                    </Link> */}
                </div>
            }
        </div>
    )
}