import { useEffect, useState } from "react"
import useCompanies from "../../hooks/useCompanies"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/free-solid-svg-icons"

export default function BlueprintForm({ formData, progress, handleChange, companies, handleCompany, handleCampaign, handleSelectedFile, selectedFile }) {

    //STATE
    const [ selectedCompany, setSelectedCompany ] = useState( '' )
    const [ selectedCampaign, setSelectedCampaign ] = useState( '' )
    const [ campaigns, setCampaigns ] = useState( [] )

    //HOOK
    const { getCompanyCampaigns, getCompanyById, getCampaignById } = useCompanies()

    //FUNCTION
    const handleSelectCompany = async ( e ) => {
        
        //GETS VALUE FROM SELECTED OPTION AND ASSIGNS VALUE TO SELECT
        const value = e.target.value
        setSelectedCompany( value ) 

        //GETS FULL COMPANY INFORMATION
        const selectedCompany = await getCompanyById( value )

        //UPDATES COMPANY IN FORM
        handleCompany( selectedCompany )
    }

    const handleSelectCampaign = async ( e ) => {
        
        //GETS VALUE FROM SELECTED OPTION AND ASSIGNS VALUE TO SELECT
        const value = e.target.value
        setSelectedCampaign( value ) 

        //GETS FULL COMPANY INFORMATION
        const selectedCampaign = await getCampaignById( value )

        //UPDATES COMPANY IN FORM
        handleCampaign( selectedCampaign )
    }

 
    //EFFECTS
    useEffect(() => {

        if( selectedCompany.length > 0 ) {

            const getCampaigns = async () => {

                const data = await getCompanyCampaigns( selectedCompany )
                setCampaigns( data )
            }
            getCampaigns()

        } else {
            setCampaigns( [] )
        }

    }, [ selectedCompany, getCompanyCampaigns ])

    return(
        <form className="brief-form">
            { progress === 1 &&
                <div className="first-step form-step">
                    <h4>Please select the Company and Campaign for which you want the Blueprint.</h4>
                    <label htmlFor="companies-select">Company</label>
                    <select
                        id={'companies-select'}
                        value={ selectedCompany }
                        name='company'
                        onChange={ handleSelectCompany }
                    >
                        <option value="" disabled>Select a company</option>
                        { companies.length > 0 && companies.map(( company, idx) => {
                                return(
                                    <option value={ company.id } key={ idx }>{ company.displayName }</option>
                                )
                            })
                        }
                    </select>
                    <label htmlFor="campaign-select">Campaign</label>
                    <select
                        id={'campaign-select'}
                        value={ selectedCampaign }
                        name='campaign'
                        onChange={ handleSelectCampaign }
                    >   
                        <option value="" disabled>Select a campaign</option>
                        { campaigns.length > 0 && campaigns.map(( campaign, idx) => {
                        
                                return(
                                    <option value={ campaign.id } key={ idx }>{ campaign.displayName }</option>
                                )
                            })
                        }
                    </select>
                </div>
            }
            { progress === 2 &&
                <div className="third-step form-step extended">
                    <h4>Describe your Product / Service:</h4>
                    <label>
                        Describe your Product / Service:
                        <span>Who you are, who you serve. how you serve them?</span>
                        <span>What makes you unique and different from the competition?</span>
                        <span>What is the #1 pain or goal you solve?</span>
                        <textarea type="text" name="product" placeholder="Type your answer here." autoComplete="off" className=" round-btn" onChange={ handleChange } value={ formData.product }></textarea>
                    </label>
                </div>
            }
            { progress === 3 &&
                <div className="fourth-step form-step extended">
                    <h4>Additional Context:</h4>
                    <label>
                        <span>Any information that would be good to know about your business.</span>
                        <span>Brag a bit around your product/service!</span>
                        <textarea 
                        type="text" 
                        name="context" 
                        placeholder="Type your answer here." 
                        autoComplete="off" 
                        className=" round-btn" 
                        onChange={ handleChange } 
                        value={ formData.context }
                        maxLength={10000}
                        >

                        </textarea>
                    </label>
                    {/* <div className="file-upload-input-container">
                        {
                            !selectedFile ?
                            <>
                                <p>Or select a file to upload</p>
                                <div className="upload-inner-container">
                                    <label htmlFor="file-input" className="btn">Select File</label>
                                    <input type="file" id='file-input' accept='.txt, .rtf, .doc, .docx, .odt, text/plain, application/msword' onChange={ ( e ) => handleSelectedFile( e.target.files[0] )}/>
                                </div>
                            </>
                            :
                            <div className="selected-file-container">
                                <p>File selected: { selectedFile.name }</p>
                                <button className="remove-file-btn" onClick={ () =>  handleSelectedFile( null )}>
                                    <p>Remove file</p>
                                    <FontAwesomeIcon icon={ faXmark }/>
                                </button>
                            </div>
                        }
                    </div> */}
                </div>
            }
        </form>
    )
}