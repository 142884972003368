import { useContext, useState, useEffect, useCallback } from "react"
import { AppContext } from "../../context/AppContext"
import Loader from "../Loader/Loader"
import BackBtn from '../BackBtn/BackBtn'
import Swal from "sweetalert2"
import useAccounts from "../../hooks/useAccounts"
import { useNavigate } from "react-router-dom"
import AdminItem from "../AdminItem/AdminItem"

export default function AccountsContainer() {

    //STATE
    const [ isLoading, setIsLoading ] = useState( true )
    const [ allAccounts, setAllAccounts ] = useState( null )
    const [ activeAccounts, setActiveAccounts ] = useState( null )
    const [ inactiveAccounts, setInactiveAccounts ] = useState( null )
    
    //CONTEXT
    const { darkMode } = useContext( AppContext ) 

    //ROUTER
    const navigate = useNavigate()

    //HOOKS
    const { getAllAccounts, updateAccountStatus, setAccountCredits } = useAccounts()

    //FUNCTIONS
    const handleStatus = async ( itemId, status ) => {
        try {
            setIsLoading( true )
            await updateAccountStatus( itemId, status )
            // //UPDATES ACCOUNTS DATA
            const data = await getAllAccounts()
            setAllAccounts( data )
            updateAccountsStatus()
            setIsLoading( false )

        } catch ( error ) {
            setIsLoading( false )
            Swal.fire({
                title: 'Oops!',
                text: 'Account status could not be updated',
                icon: 'error',
                confirmButtonText: 'Ok',
                buttonsStyling: false,
                customClass: {
                    popup: 'marketsauce-alert-container round-div div-shadow',
                    icon: 'alert-icon',
                    confirmButton: 'confirm-btn btn order2',
                    denyButton: 'deny-btn btn order1',
                }
            })
        }
    }

    const updateAccountsStatus = useCallback(() => {
        const activeArray = allAccounts.filter( account => account.isActive === true )
        setActiveAccounts( activeArray.length > 0 ? activeArray : null )

        const inactiveArray = allAccounts.filter( account => account.isActive === false )
        setInactiveAccounts( inactiveArray.length > 0 ? inactiveArray : null )
    }, [ allAccounts ])

    const getAccounts = useCallback( async () => {
        try {
            const data = await getAllAccounts()
            setAllAccounts( data )

        } catch ( error ) {
            console.error( error.message )
            Swal.fire({
                title: 'Oops!',
                text: 'We could not retrieve accounts',
                icon: 'error',
                confirmButtonText: 'Ok',
                buttonsStyling: false,
                customClass: {
                    popup: 'marketsauce-alert-container round-div div-shadow',
                    icon: 'alert-icon',
                    confirmButton: 'confirm-btn btn order2',
                    denyButton: 'deny-btn btn order1',
                }
            })
        }
    }, [ getAllAccounts ] )

    const submitChange = async ( accountId, credits ) => {
        try {
            setIsLoading( true ) 
            await setAccountCredits( accountId, credits )
            await getAccounts()
            setIsLoading( false )

        } catch ( error ) {
            console.error( error.message )
            Swal.fire({
                title: 'Oops!',
                text: 'We had some issues processing your request.',
                icon: 'error',
                confirmButtonText: 'Ok',
                buttonsStyling: false,
                customClass: {
                    popup: 'marketsauce-alert-container round-div div-shadow',
                    icon: 'alert-icon',
                    confirmButton: 'confirm-btn btn order2',
                    denyButton: 'deny-btn btn order1',
                }
            })
        }
    }

    //EFFECTS
    useEffect(() => {

        getAccounts()

    }, [ getAccounts ] )

    useEffect(() => {
        if ( allAccounts && allAccounts.length > 0 ){
            updateAccountsStatus()
            setIsLoading( false )
        }
    }, [ allAccounts, updateAccountsStatus ])


    return(
        <div className={`admin main-container ${ !darkMode ? 'light' : '' }`}>
            <div className="inner-container round-div div-shadow">
            <BackBtn action={ ()=> navigate('/admin') }/>
                
                {   isLoading ? 
                    <Loader/>
                    :
                    <>
                    { inactiveAccounts &&
                        <>
                            <h5>{`Accounts Pending Activation${inactiveAccounts.length > 0 ? ' (' +inactiveAccounts.length + ')': ' (0)'}`}</h5>
                            <div className="item-list-container">
                                <div className='admin-item header ' >
                                    <p className="size-m">Account Name</p>
                                    <p className="size-s">Credits</p>
                                    <p className="size-s">Enabled</p>
                                </div>
                                {   
                                    inactiveAccounts.map( ( account, idx ) => {
                                        return(
                                            <AdminItem key={ idx} action={ handleStatus } displayName={ account.name } status={ account.isActive } itemId={ account.id } extended={ true } defValue={ account.credits } submitChange={ submitChange } deployable={ true }/>
                                        )
                                    })
                                }
                            </div>
                        </>
                    }
                    { activeAccounts &&
                        <>
                            <h5>{`Active Accounts${activeAccounts.length > 0 ? ' (' +activeAccounts.length + ')': ' (0)'}`}</h5>
                            <div className="item-list-container">
                                <div className='admin-item header'>
                                    <p className="size-m">Account Name</p>
                                    <p className="size-s">Credits</p>
                                    <p className="size-s">Enabled</p>
                                </div>
                                {   
                                    activeAccounts.map( ( account, idx ) => {
                                        return(
                                            <AdminItem key={ idx} action={ handleStatus } displayName={ account.name } status={ account.isActive }  itemId={ account.id } extended={ true } defValue={ account.credits } submitChange={ submitChange } deployable={ true } />
                                        )
                                    })
                                }
                            </div>
                        </>
                    }
                    </>
                }

            </div>

        </div>
    )
}