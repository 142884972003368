import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { useContext } from "react"
import { AppContext } from "../../context/AppContext"

export default function BackBtn({ action }) {
    
    //CONTEXT
    const { darkMode } = useContext( AppContext ) 
    return(
        <div className={`back-btn-container ${ !darkMode ? 'light': ''}`} onClick={ action }>
            <FontAwesomeIcon icon={ faChevronLeft }/>
        </div>
    )
}