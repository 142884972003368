import { useCallback, useContext } from "react"
import axios from 'axios'
import { AppContext } from "../context/AppContext"


function useChats( ) {

    //CONTEXT
    const { authToken } = useContext( AppContext )

    const getAvatarThreads = useCallback( async ( avatarId ) => {
        
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/chat/avatar_threads/${ avatarId }`, {
                headers: { Authorization: `Bearer ${ authToken }`}
            })
            const threadsArray = res.data
            
            return threadsArray

        } catch ( err ) {

            console.log( err );
        }
    }, [ authToken ] )


    const createThread = async ( accountId, avatarId ) => {
        try {
            const payload = {
                accountId,
                avatarId,
                assistantId: 'asst_9nNyvwXBmIYxX5qsg8KN3NEI'
            }

            const res = await axios.post(`${process.env.REACT_APP_API_URL}/chat/new_thread`, payload, {
                headers: { Authorization: `Bearer ${ authToken }`}
            })
            
            return res.data
            
        } catch ( err ) {

            console.log( err );
        }
    }

    const sendMessage = async ( message, threadId ) => {
        try {
            const payload = {
                message, 
                threadId,
                assistantId: 'asst_9nNyvwXBmIYxX5qsg8KN3NEI'
            }

            const res = await axios.post(`${process.env.REACT_APP_API_URL}/chat/message`, payload, {
                headers: { Authorization: `Bearer ${ authToken }`}
            })
            
            return res.data
            
        } catch ( err ) {

            console.log( err );
        }
    }


    return {
        getAvatarThreads,
        createThread,
        sendMessage
    }
}

export default useChats