export default function FormProgressBar({ progress, darkMode }) {
    return(
        <div className={`progressbar-container ${ !darkMode ? 'div-shadow' : ''}`}>
            <div className={`step-container ${ progress === 1 && 'active'} ${ progress > 1 && 'complete '}`}>1</div>
            {/* <span className={`${ progress > 1 && 'complete '}`}></span> */}
            <div className={`step-container ${ progress === 2 && 'active'} ${ progress > 2 && 'complete '}`}>2</div>
            {/* <span className={`${ progress > 2 && 'complete '}`}></span> */}
            <div className={`step-container ${ progress === 3 && 'active'} ${ progress > 3 && 'complete '}`}>3</div>
            {/* <span className={`${ progress > 3 && 'complete '}`}></span>
            <div className={`step-container ${ progress === 4 && 'active'}`}>4</div> */}
        </div>
    )
}