import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default function AdminPanelContainer() {

    return(
        <div className={`settings main-container`}>
            <div className="inner-container round-div div-shadow">
                <div className="settings-button-container">
                    <Link to={'/admin/users'}>
                        <div className="settings-button round-div div-shadow">
                            <FontAwesomeIcon icon={ faUserPlus }/>
                            <h4>Users</h4>
                            <p>Manage users privileges</p>
                        </div>
                    </Link>
                </div>
                <div className="settings-button-container">
                    <Link to={'/admin/accounts'}>
                        <div className="settings-button round-div div-shadow">
                            <FontAwesomeIcon icon={ faBuilding }/>
                            <h4>Accounts</h4>
                            <p>Manage Accounts & Memberships</p>
                        </div>
                    </Link>
                </div>
        
            </div>
        </div>
    )
}