import app from "../fb";
import { getAuth, signInWithPopup, GoogleAuthProvider, getAdditionalUserInfo, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from "firebase/auth"
import { v4 as uuidv4 } from 'uuid'
import { useCallback, useContext } from "react";
import axios from 'axios'
import { AppContext } from "../context/AppContext";

function useAccounts( ) {

    //FIRESTORE 
    const auth = getAuth( app )
    //Gets provider for auth
    const provider = new GoogleAuthProvider() 

    //CONTEXT
    const { authToken, deleteSessionData } = useContext( AppContext )

    //FUNCTIONS
    //OPEN ACCESS
    const handleNewUser =  async ( data, usingGoogle ) => {
        try {

            const userId = uuidv4()
            const accountId = uuidv4()

            await createUser( data, usingGoogle, userId, accountId )
            await createAccount( data.company, accountId, userId )

        } catch ( err ) {
            throw err
        }
    }

    const createJoinerUser = async ( user ) => {
        try {
            //CREATES USER IN DB   
            await axios.post(`${process.env.REACT_APP_API_URL}/accounts/user`, user, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })       

        } catch ( err ) {
            throw err
        }
    }

    const updateAccountUsers = async ( accountId, userId ) => {
        try {
            const newUser = { userId }

            //UPDATES ACCOUNT USERS   
            await axios.patch(`${ process.env.REACT_APP_API_URL }/accounts/${ accountId }/users`, newUser, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })       

        } catch ( err ) {
            throw err
        }
    }

    const deleteInvitation = async ( token ) => {
        try {
            //DELETES INVITE FROM DB   
            await axios.delete(`${process.env.REACT_APP_API_URL}/accounts/invitation/${ token }`)    

        } catch ( err ) {
            throw err
        }
    }

    const handleJoiningUser = async ( data ) => {
        try{
            const userId = uuidv4()
            const accountId = data.account

            const user = {
                id: userId,
                name: data.name,
                lastname: data.lastname,
                account: accountId,
                email: data.email,
                isAdmin: false
            }

            //CALLS GOOGLE AUTH FUNCTION
            await createUserWithEmailAndPassword( auth, data.email, data.password )

            // //CREATES USER IN DB
            await createJoinerUser( user )

            //UPDATES ACCOUNT INFORMATION, ADDING USER
            await updateAccountUsers( accountId, userId )

            //DELETES INVITE FROM DB
            await deleteInvitation( data.token )

        } catch ( err ){
            throw err
        }
    }

    const createUser = async ( data, usingGoogle, userId, accountId ) => {
        
        try{
            //CREATES USER OBJECT WITH FORM INFORMATION
            const user = {
                id: userId,
                name: data.name,
                lastname: data.lastname,
                account: accountId,
                email: data.email.toLowerCase(),
                isAdmin: false,
            }
            if( !usingGoogle ) {
                //CALLS GOOGLE AUTH FUNCTION
                await createUserWithEmailAndPassword( auth, data.email, data.password )
            }

            //CREATES USER IN DB   
            await axios.post(`${process.env.REACT_APP_API_URL}/accounts/user`, user, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })     

        } catch ( err ) {
            //THROWS ERROR
            throw err
        }   
    }

    const createAccount = async ( name, accountId, userId ) => {

        const newAccount = {
            id: accountId,
            name: name,
            membership: null,
            isActive: false,
            creditBased: true,
            credits: 1,
            onboarded: false,
            users: [ userId ],
        }

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/accounts`, newAccount, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })

        } catch ( err ) {
            throw err
        }
    }

    const userLogin = async ( data ) => {

        try{
            await signInWithEmailAndPassword(auth, data.email, data.password)

        } catch ( err ){

            //THROWS ERROR
            throw err
        }
    }

    const getUserByEmail = useCallback( async ( email, token ) => {

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/accounts/user/${ email }`, {
                headers: { Authorization: `Bearer ${ token }`}
            })
            const user = res.data[0]
            return user

        } catch (err) {
            throw err
        }

    }, [ ] )

    const getAccountById = useCallback( async ( id, token ) => {

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/accounts/${ id }`, {
                headers: { Authorization: `Bearer ${ token }`}
            })
            const accountData = res.data[0]
            return accountData

        } catch (err) {
            throw err
        }

    }, [] )

    const googleSignIn = async ( ) => {

        try{

            //CALLS GOOGLE AUTHENTICATION FUNCTION
            const res = await signInWithPopup( auth, provider )
            //GETS USER DATA ONCE SIGNED IN
            const userInfo = getAdditionalUserInfo( res )

            //RETURNS RESPONSE FROM GOOGLE
            return userInfo 

        } catch ( err ) {

            //RETURNS ERROR
            throw err
        }
    }

    const handleSignOut = async () => {
        try{    
            deleteSessionData()
            await signOut( auth );
        } catch ( err ){
            console.log( err );
        }
    }

    const sendResetPasswordEmail = async ( email ) => {
        try {
            const actionCodeSettings = {
                url: `https://portal.marketsauce.ai/`,
            }
            await sendPasswordResetEmail( auth, email, actionCodeSettings )
        } catch ( err ) {
            throw err
        }
    }

    const updateAccountCredits = async ( account, credits ) => {

        try {
            //VARIABLES
            const accountId = account.id 
            const creditInfo = { credits: credits }

            //UPDATES ACCOUNT CREDITS AFTER PURCHASE   
            await axios.patch(`${ process.env.REACT_APP_API_URL }/accounts/${ accountId }/remove_credits`, creditInfo, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${ authToken }`
                }
            })       
        } catch ( error ) {
            throw error
        }
    }

    const setAccountCredits = async ( accountId, credits ) => {

        try {
            const creditInfo = { credits: credits }
            //UPDATES ACCOUNT CREDITS    
            await axios.patch(`${ process.env.REACT_APP_API_URL }/accounts/${ accountId }/set_credits`, creditInfo, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${ authToken }`
                }
            })       
        } catch ( error ) {
            throw error
        }
    }

    const createInvitation = async ( account, email ) => {
        
        try {
            const inviteToken = uuidv4()
            const url = `http://portal.marketsauce.ai/invite/${ account.id }/${ inviteToken }`
            const timestamp = Date.now()

            const invitation = {
                accountId: account.id,
                accountName: account.name,
                email,
                token: inviteToken,
                url,
                timestamp,
            }

            //CALLS API TO POST NEW INVITATION. API WILL TRIGGER AUTOMATION TO SEND EMAIL TO INVITED USER
            await axios.post(`${process.env.REACT_APP_API_URL}/accounts/invite`, invitation, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${ authToken }`
                }
            })

        } catch ( err ) {
            throw err
        }
    }

    const getInvitationById = useCallback( async ( invitationToken ) => {

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/accounts/invitation/${ invitationToken }`)
            const invitation = res.data[0]
            return invitation

        } catch (err) {
            throw err
        }
    }, [] )

    const getAccountUsers = useCallback( async ( accountId ) => {

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/accounts/${ accountId }/users`, {
                headers: { Authorization: `Bearer ${ authToken }`}
            })
            const accountUsers = res.data
            return accountUsers

        } catch (err) {
            throw err
        }

    }, [ authToken ] )

    const getAccountInvitations = useCallback( async ( accountId ) => {

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/accounts/${ accountId }/invitations`, {
                headers: { Authorization: `Bearer ${ authToken }`}
            })
            const accountInvitations = res.data
            return accountInvitations

        } catch (err) {
            throw err
        }

    }, [ authToken ] )

    const isValidEmail = ( email )  => {

        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        return emailPattern.test(email);
    }

    //FUNCTIONS
    //ADMIN
    const getAllAccounts = useCallback( async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/accounts/`, {
                headers: { Authorization: `Bearer ${ authToken }`}
            })
            const accounts = res.data
            return accounts
        } catch (error) {
            throw error
        }
    }, [ authToken ])

    const getAllUsers = useCallback( async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/accounts/users`, {
                headers: { Authorization: `Bearer ${ authToken }`}
            })
            const users = res.data
            return users
        } catch (error) {
            throw error
        }
    }, [ authToken ])

    const updateUserPermissions =  async ( itemId, status, email ) => {
        try {
            const body = { 
                admin: !status,
                userId: itemId
            }
            //UPDATES USER PERMISSIONS   
            await axios.patch(`${ process.env.REACT_APP_API_URL }/accounts/admin/${ email }`, body, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${ authToken }`
                }
            })    
            
        } catch ( error ) {
            throw error
        }
    }

    const updateAccountStatus = async ( accountId, status ) => {
        try {
            const updatedStatus = { isActive : !status }
            ///UPDATES ACCOUNT STATUS   
            await axios.patch(`${ process.env.REACT_APP_API_URL }/accounts/${ accountId }/status`, updatedStatus, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${ authToken }`
                }
            })    

        } catch (error) {
            throw error
        }
    }

    const updateAccountOnboarded = useCallback( async ( accountId ) => {
        try {
            const updatedStatus = { onboarded : true }

            ///UPDATES ACCOUNT STATUS   
            await axios.patch(`${ process.env.REACT_APP_API_URL }/accounts/${ accountId }/onboarded`, updatedStatus, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })   

        } catch (error) {
            throw error
        }
    }, [ ] )

    const getAccountTransactions = useCallback ( async ( accountId ) => {
        
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/accounts/${ accountId }/credit_transactions`, {
                headers: { Authorization: `Bearer ${ authToken }`}
            })
            const accountTransactions = res.data
            return accountTransactions

        } catch (err) {
            throw err
        }

    }, [ authToken ])

    return{
        updateAccountCredits,  
        getAccountUsers,
        userLogin,
        googleSignIn,
        handleNewUser,      
        getUserByEmail,
        getAccountById,
        handleSignOut,
        createInvitation,
        getAccountInvitations,
        getInvitationById,
        handleJoiningUser,
        isValidEmail,
        sendResetPasswordEmail,
        getAllAccounts,
        updateAccountStatus,
        updateUserPermissions,
        getAllUsers,
        updateAccountOnboarded,
        setAccountCredits,
        getAccountTransactions,
    }
}

export default useAccounts