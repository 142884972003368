import { useCallback } from "react"

function usePrompts( ) {

    const getAllPrompts = useCallback( async () => {
        const array = [
            {
                title: 'Generating Pinterest Board Ideas',
                category: 'Social Media',
                content: 'Can you come up with some Pinterest board ideas for [specific audience, such as students/parents/teachers etc.]?',
            },
            {
                title: 'Automate Instagram Growth',
                category: 'Social Media',
                content: 'Extract insights, identify risks, and distill key information from long corporate reports into a single memo.',
            },
            {
                title: 'Generating Engaging Content Ideas',
                category: 'Social Media',
                content: 'Extract insights, identify risks, and distill key information from long corporate reports into a single memo.',
            },
            {
                title: 'Creating Sales Scripts',
                category: 'Sales',
                content: 'I want to make my [content format] more interesting, can you give me some ideas?',
            },
            {
                title: 'Automate Instagram Growth',
                category: 'Sales',
                content: 'I want to create a sense of [ADJECTIVE] urgency and scarcity in my sales pitch. Can you help me come up with some language that will encourage [TARGET AUDIENCE] to [SPECIFIC ACTION] and not miss out on [PRODUCT/SERVICE]? What [SPECIFIC INCENTIVE] can I offer to make this happen?.',
            },
            {
                title: 'Conducting Sales Outreach',
                category: 'Sales',
                content: 'As a follow-up to my initial outreach, can you suggest ways to [e.g., CONTINUE THE CONVERSATION/NURTURE THE LEAD] while [e.g., ADDING VALUE/PROVIDING INSIGHTS] and [e.g., BUILDING A RELATIONSHIP/ESTABLISHING TRUST] with the lead?',
            },
            {
                title: 'Planning Podcast Episodes',
                category: 'Podcast Production',
                content: 'For my podcast episode on [TOPIC], can you provide me with [NUMBER] unique and [ADJECTIVE] angles or subtopics that will appeal to [TYPE OF AUDIENCE], and suggest [NUMBER] potential guests who can offer their expertise on each subtopic?',
            },
            {
                title: 'Conversion Optimization',
                category: 'Copywriting',
                content: 'Create a landing page that captures the attention of my [target persona] and drives them to take [desired action] by utilizing persuasive language, compelling visuals, and a clear call to action.',
            },
            {
                title: 'Generating Content Ideas',
                category: 'SEO',
                content: 'I am trying to expand my content portfolio by writing about [industry/niche]. Can you help me come up with a list of topics that would be of interest to my [target audience]?',
            },
            {
                title: 'Implementing Upselling Strategies',
                category: 'Website',
                content: 'How can [specific industry] company implement successful upselling techniques for [product/service]?',
            },
            {
                title: 'Building Email Automation',
                category: 'Email Marketing',
                content: 'What are some best practices for designing an email automation for [specific type of audience or industry]?',
            },
            {
                title: 'Writing Email Subject Lines',
                category: 'Email Marketing',
                content: 'I am crafting an email to promote our [product/service], and I need a compelling subject line that will entice recipients to open the email. Can you help me generate several options?',
            }
        ]

        return array
    },[] )




    return {
        getAllPrompts
    }
}

export default usePrompts