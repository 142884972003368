import { useContext } from "react"
import { AppContext } from "../context/AppContext"
import GlobalLoader from "../components/GlobalLoader/GlobalLoader"
import Login from "./Login"
import SideBarContainer from "../components/SideBarContainer/SideBarContainer"
import TopBarContainer from "../components/TopBarContainer/TopBarContainer"
import BoardContainer from "../components/BoardContainer/BoardContainer"
import AccountInactiveMessage from "../components/AccountInactiveMessage/AccountInactiveMessage"
import SettingsContainer from "../components/SettingsContainer/SettingsContainer"
 
export default function Settings() {

    //CONTEXT
    const { globalUser, globalLoader, globalAccount, darkMode } = useContext( AppContext )
    return(
        <>
        { globalLoader ?

            <GlobalLoader/>
            :
            <>
            { !globalUser ?
                <Login/>
                :
                <div className={`view-container ${ !darkMode ? 'light' : ''}`}>
                    <SideBarContainer/>
                    <TopBarContainer/>
                    <BoardContainer sectionTitle={ `Account Settings` } faded={ false } section={ globalAccount && !globalAccount.isActive ?
                    <AccountInactiveMessage/> : <SettingsContainer darkMode={ darkMode } /> } />
                </div>
            }
            </>
        }
        </>
    )
}