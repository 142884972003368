import { faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export default function ChatContainer() {

    return (
        <div className={`chat main-container`}>
                {/* <div className="settings-button-container">
                    <Link to={'/chat/avatar'}>
                        <div className="settings-button round-div div-shadow">
                            <FontAwesomeIcon icon={ faUser }/>
                            <h4>Avatar Chat</h4>
                            <p>Chat with a single avatar</p>
                        </div>
                    </Link>
                </div> */}
                <div className="settings-button-container">
                    <div className="settings-button round-div div-shadow">
                        <FontAwesomeIcon icon={ faUser } className="incoming"/>
                        <h4 className="incoming">Avatar Chat</h4>
                        <p>Coming soon</p>
                    </div>
                </div>
                <div className="settings-button-container">
                    <div className="settings-button round-div div-shadow">
                        <FontAwesomeIcon icon={ faUsers } className="incoming"/>
                        <h4 className="incoming">Focus Group</h4>
                        <p>Coming soon</p>
                    </div>
                </div>
        </div>
    )

}