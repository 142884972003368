import { useCallback, useContext, useState } from 'react'
import { getStorage, getDownloadURL, ref } from "firebase/storage"
import app from '../fb'
import axios from 'axios'
import { AppContext } from '../context/AppContext'

function useAvatars(  ) {

    //FIRESTORE
    const storage = getStorage( app )
    
    //STATE
    const [ avatars, setAvatars ] = useState( null )
    const [ targetAvatar, setTargetAvatar ] = useState( null )

    //CONTEXT
    const { authToken, updateContextAvatars, userAvatars } = useContext( AppContext )

    //FUNCTIONS
    const getImageUrl = useCallback( async ( imgName ) => {
        //BASE URL FOR IMAGES
        const fileLocation = `gs://marketsauce-portal.appspot.com/avatars/${ imgName }.jpg`
        try{
            const imageRef = ref( storage, fileLocation)
            const url = await getDownloadURL( imageRef )
            return url
        } catch ( err ){
            console.log( err );
        }
    }, [ storage ] )

    const updateImgUrlInArray = useCallback( async ( array ) => {

        try{
            const updatedArray = await Promise.all( array.map( async ( item ) => {
                try {
                    const imgName = item.data.img
                    const imgUrl = await getImageUrl( imgName )
                    return(
                        {
                            ...item,
                            data: {
                                ...item.data,
                                img: imgUrl
                            }
                        }
                    )
                } catch ( err ) {
                    console.log(err);
                    return item
                }
            }))
            return updatedArray

        } catch ( err ) {
            console.log( err )
        }
    }, [ getImageUrl ] )

    const fetchAccountAvatars = useCallback( async ( accountId, token ) => {

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/avatars/account/${ accountId }`, {
                headers: { Authorization: `Bearer ${ token }`}
            })
            const accountAvatars = res.data

            //GETS URL FOR IMAGES IN ARRAY
            const updatedArray = await updateImgUrlInArray( accountAvatars ) 

            //SETS ARRAY TO avatars STATE
            setAvatars( updatedArray )
            return updatedArray

        } catch ( err ) {

            //THROWS ERROR IN CONSOLE AND SETS avatar TO EMPTY ARRAY
            setAvatars( [] )
        }
    }, [ updateImgUrlInArray ])


    const handleTargetAvatar = ( avatar ) => {
        setTargetAvatar( avatar )
    }

    const formatAvatar = useCallback( async ( avatar, avatarId ) => {
        const formattedAvatar = {
            id: avatarId,
            data: {
                id: avatar.snapshot.id,
                name: avatar.snapshot.name,
                gender: avatar.snapshot.gender,
                favorite: avatar.snapshot.favorite,
                age: avatar.snapshot.age,
                img: await getImageUrl(avatar.snapshot.img),
                docUrl: avatar.snapshot.data.docUrl,
                occupation: avatar.snapshot.occupation,
                companyName: avatar.snapshot.data.companyName,
                companyId: avatar.snapshot.data.companyId,
                campaignName: avatar.snapshot.data.campaignName,
                campaignId: avatar.snapshot.data.campaignId,
            },
            user: {
                accountId: avatar.snapshot.user.accountID,
                userId: avatar.snapshot.user.userId,
            }
        }
        return formattedAvatar
    }, [ getImageUrl ])

    const getAvatarById = useCallback(async ( id ) => {
        
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/avatars/${ id }`, {
                headers: { Authorization: `Bearer ${ authToken }`}
            })
            const avatar = await formatAvatar( res.data[0], id )
            return avatar

        } catch (err) {
            throw err 
        }
    },[ authToken, formatAvatar ])

    const updateFavoriteInDb = async ( id, updatedFav ) => {

        const newStatus = { favorite: updatedFav }

        try {
            // //UPDATES ACCOUNT CREDITS AFTER PURCHASE   
            await axios.patch(`${ process.env.REACT_APP_API_URL }/avatars/${ id }/favorite`, newStatus, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${ authToken }`
                }
            })  
            
        } catch ( err ) {
            throw err 
        }
    }

    const updateAvatarName = async ( id, name ) => {

        const update = { name: name }

        try {
            // //UPDATES ACCOUNT CREDITS AFTER PURCHASE   
            await axios.patch(`${ process.env.REACT_APP_API_URL }/avatars/${ id }/name`, update, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${ authToken }`
                }
            })  
            
        } catch ( err ) {
            throw err 
        }
    }

    const toggleFavorite = async ( avatar ) => {
        const updatedFav = !avatar.data.favorite
        const id = avatar.id
        //FIRST, FINDS THE OBJECT IN userAvatars ARRAY
        const index = userAvatars.findIndex( avatar => avatar.id === id )

        if( index !== -1 ) {

            //IF INDEX IS FOUND, CREATES NEW OBJECT FOR UPDATED AVATAR
            const updatedAvatar = {
                ...userAvatars[ index ],
                data: {
                    ...userAvatars[ index ].data,
                    favorite: updatedFav
                }
            }

            //SLICES ARRAY AND REPLACES THE AVAYAR ELEMENT ON THIS INDEX
            const updatedArray = [
                ...userAvatars.slice( 0, index ),
                updatedAvatar,
                ...userAvatars.slice( index + 1 )
            ]

            //UPDATE AVATARS ARRAY IN CONTEXT
            updateContextAvatars( updatedArray )

            //UPDATES AVATAR FAV STATE IN DB
            await updateFavoriteInDb( id, updatedFav )
        }
    }

    const updateAvatars = async ( accountId ) => {
        const array = await fetchAccountAvatars ( accountId, authToken )
        updateContextAvatars( array )
    }
 

    return {
        avatars,
        fetchAccountAvatars,
        targetAvatar,
        handleTargetAvatar,
        getAvatarById,
        toggleFavorite,
        updateAvatarName,
        updateAvatars
    }
}

export default useAvatars