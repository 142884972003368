import { useContext, useEffect, useState } from "react"
import { AppContext } from "../../context/AppContext"
import { Link } from 'react-router-dom'
import FavoriteBtn from "../FavoriteBtn/FavoriteBtn"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faPen, faXmark } from "@fortawesome/free-solid-svg-icons"

export default function AvatarCard({ avatar, darkMode, handleNameUpdate }) {

    //STATE
    const [ showEdit, setShowEdit ] = useState( null )
    const [ nameInputValue, setNameInputValue ] = useState( '' )

    //CONTEXT
    const { capitalizeWords } = useContext( AppContext )

    //FUNCTIONS
    const handleChange = ( e ) => {
        const name = e.target.value
        setNameInputValue( name )
    }

    const handleClose = () => {
        setShowEdit( false )
        setNameInputValue( avatar.data.name )
    }
    
    const handleKeyDown = ( e ) => {
        if ( e.key === 'Enter') {
            confirmUpdate()
        }
    }

    const confirmUpdate = () => {
        const id = avatar.id
        const name = nameInputValue
        handleNameUpdate( id, name )
    }

    //EFFECTS
    useEffect(() => {
        setNameInputValue( avatar.data.name )
    }, [ avatar.data.name ])

    return(
        <div className={`avatar-card-container ${ !avatar && 'company'}`}>
            <div className={`avatar-card round-div div-shadow ${ !darkMode ? 'light' : ''}`}>
                <FavoriteBtn avatar={ avatar }  />
                <div className="avatar-card-img-container desktop">
                    <img src={ avatar.data.img } alt={ avatar.data.name } />
                    <h4 >{capitalizeWords( avatar.data.name )}</h4>
                </div>
                <div className="avatar-card-snapshot-container">
                    <div className="editable-div">
                        {
                            ! showEdit ?
                            <h4 className="editable-div">{capitalizeWords( avatar.data.name )}</h4>
                            :
                            <input className="editable-div" type="text" value={ nameInputValue } onChange={ handleChange } onKeyDown={ handleKeyDown }/>
                        }

                        <div className="btn-container">
                        {
                            ! showEdit ?

                            <FontAwesomeIcon icon={ faPen } className="edit-icon" onClick={ () => setShowEdit( true )}/>
                            :
                            <>
                            <FontAwesomeIcon icon={ faCheck } className="edit-icon" onClick={ confirmUpdate }/>
                            <FontAwesomeIcon icon={ faXmark } className="edit-icon red" onClick={ handleClose }/>
                            </>
                        }
                        </div>
                    </div>
                    <p><span>Age: </span> { avatar.data.age }</p>
                    <p><span>Gender: </span>{capitalizeWords( avatar.data.gender )}</p>
                    <p><span>Occupation: </span> {capitalizeWords( avatar.data.occupation )}</p>
                </div>
                <div className="avatar-card-snapshot-container">
                    <p><span>Company: </span> { capitalizeWords( avatar.data.companyName ) }</p>
                    <p><span>Campaign: </span> {capitalizeWords( avatar.data.campaignName )}</p>
                </div>
                <div className="avatar-card-btn-container">
                    <Link to={`/avatarDetail/${avatar.data.id}`} >
                        <button className="btn main-btn round-btn ">Open</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}