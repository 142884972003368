import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons'
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

export default function DarkToggleBtn({ sideBarExpanded, desktop, toggleVisible }) {

    //CONTEXT
    const { darkMode, toggleDarkMode } = useContext( AppContext )

    //FUNCTION
    const handleClick = () => {
        toggleDarkMode()

        if( !desktop ) {
            toggleVisible()
        }
    }

    return(
        <li>
            <div className={` nav-btn round-btn dark-mode-btn ${!sideBarExpanded && 'centered'} ${ !darkMode ? 'light' : ''} ${ !desktop ? 'mobile' : ''}`} onClick={ handleClick } >
                    <FontAwesomeIcon icon={ darkMode ? faSun : faMoon }/>
                    {
                        sideBarExpanded && 
                        <p className={`${sideBarExpanded ? 'full-width' : 'no-width'}`}>{ darkMode ? 'Light Mode' : 'Dark Mode' }</p>
                    }
                </div>
        </li>
    )
}