import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from '@fortawesome/free-brands-svg-icons'

export default function GoogleSignInBtn({ handleGoogleSignIn }) {
    return(

        <button className="btn google-signin" onClick={ handleGoogleSignIn }>
            Continue with Google
            <FontAwesomeIcon icon={ faGoogle }/>
        </button>
    )
}