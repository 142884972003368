import { useEffect, useState } from "react"
import Loader from "../components/Loader/Loader"
import useAccounts from "../hooks/useAccounts"
import Swal from "sweetalert2"
import { useNavigate, Link } from "react-router-dom"
import Footer from "../components/Footer/Footer"

export default function Reset () {

    const [ buttonEnabled, setButtonEnabled] = useState( false )
    const [ email, setEmail ] = useState('')

    //HOOKS
    const { isValidEmail, sendResetPasswordEmail } = useAccounts()

    //ROUTER
    const navigate = useNavigate()

    //FUNCTIONS
    const handleSubmit = async ( e ) => {
        e.preventDefault()
        if( isValidEmail( email ) ){

            try {
                await sendResetPasswordEmail( email ) 
                Swal.fire({
                    title: 'Request accepted',
                    text: 'If your email is registered, you will receive an email to reset your password.',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    buttonsStyling: false,
                    customClass: {
                        popup: 'marketsauce-alert-container round-div div-shadow',
                        icon: 'alert-icon',
                        confirmButton: 'confirm-btn btn order2',
                        denyButton: 'deny-btn btn order1',
                    }
                })
                navigate('/')
                
            } catch ( error ) {
                Swal.fire({
                    title: 'Oops!',
                    text: error.message,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    buttonsStyling: false,
                    customClass: {
                        popup: 'marketsauce-alert-container round-div div-shadow',
                        icon: 'alert-icon',
                        confirmButton: 'confirm-btn btn order2',
                        denyButton: 'deny-btn btn order1',
                    }
                })
            }
        } else {
            Swal.fire({
                title: 'Oops!',
                text: 'Please enter a valid email.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                buttonsStyling: false,
                customClass: {
                    popup: 'marketsauce-alert-container round-div div-shadow',
                    icon: 'alert-icon',
                    confirmButton: 'confirm-btn btn order2',
                    denyButton: 'deny-btn btn order1',
                }
            })
        }
    }

    const handleChange = ( e ) => {
        const emailString = e.target.value
        setEmail( emailString )
    }

    useEffect(() => {
        setButtonEnabled( email !== '' ? true : false )
    }, [ email ])

    return(
        <>
            <div className="view-container login">
                <div className="login-form-container round-div div-shadow">
                    <div className="img-container">
                        <img src="/images/logo_full_dark.png" alt="marketsauce"/>
                    </div>
                    <h3>{ 'Password Recovery Service' }</h3>
                    <form className="login-form" onSubmit={ handleSubmit }>
                        <label>
                            Email:
                            <input type="email" name="email" placeholder="your@email.com" autoComplete="on" className="round-btn" onChange={ handleChange } value={ email }/>
                        </label>
                        <button className={`btn login-btn ${ !buttonEnabled ? 'disabled': '' }`} disabled={ buttonEnabled ? false : true } onClick={ handleSubmit }>{ 'Reset Password'}</button>
                    </form>
                    <Link to={'/'}>Back to login</Link>
                </div>
            </div>
            <Footer/>
        </>
    )
}