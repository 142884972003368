export default function CreateCard({ product, handleSelectedProduct }) {
    return(
        <div className="create-card div-shadow round-div">
            <div>
                <h6 className={`create-card-header ${ !product.enabled ? 'disabled': ''}`}>MarketSauce 9000</h6>
                <h4 className={`${ !product.enabled ? 'disabled': ''}`}>{ product.title }</h4>
            </div>
            <p className={`${ !product.enabled ? 'disabled': ''}`}>{ product.description }</p>
            {   product.enabled ?
                <>
                    <h5>Features</h5>
                    <ul>
                        {
                        product.features.map(( feature, idx) => {
                            return(
                                <li key={ idx }>- { feature }</li>
                            )
                        })
                        }
                    </ul>
                    <p className="price-line">Price: { product.priceInCredits} { product.priceInCredits > 1 ? 'credits' : 'credit' }.</p>
                    <p className="price-line">Delivery: { product.delivery }</p>
                </>
                :
                <h5>Available Soon</h5>
            }
            <button onClick={() => handleSelectedProduct( product )} className={`btn round-btn main-btn ${ !product.enabled ? 'disabled': ''}`} disabled={ !product.enabled }>Order</button>
        </div>
    )
}