import { useCallback, useContext, useEffect, useState } from "react";
import AvatarCard from "../AvatarCard/AvatarCard";
import Loader from "../Loader/Loader";
import NoDataMessage from "../NoDataMessage/NoDataMessage";
import { AppContext } from "../../context/AppContext";
import FilterBar from "../FilterBar/FilterBar";
import { Link } from "react-router-dom";
import useOrders from "../../hooks/useOrders";
import useAvatars from "../../hooks/useAvatars";


export default function AvatarCardsContainer ({ isLoading, cardsArray, handleNameUpdate }) {

    //STATE
    const [ filteredCards, setFilteredCards ] = useState( [] )
    const [ displayCards, setDisplayCards ] = useState( [] )
    const [ isChecked, setIsChecked ] = useState( false )
    const [ companies, setCompanies ] = useState( [] )
    const [ campaigns, setCampaigns ] = useState( [] )
    const [ selectedCompany, setSelectedCompany ] = useState( '' )
    const [ selectedCampaign, setSelectedCampaign ] = useState( '' )
    const [ pendingProducts, setPendingProducts ] = useState( null )
    const [ showFavFilter, setShowFavFilter ] = useState( false )

    //CONTEXT
    const {  darkMode, globalUser, authToken } = useContext( AppContext )

    //HOOKS
    const { getAccountOrders } = useOrders()

    //FUNCTIONS
    const handleSelectCompany = ( e ) => {

        //GETS VALUE FROM SELECTED OPTION AND ASSIGNS VALUE TO SELECT
        const value = e.target.value
        setSelectedCompany( value ) 
    }

    const handleSelectCampaign = ( e ) => {

        //GETS VALUE FROM SELECTED OPTION AND ASSIGNS VALUE TO SELECT
        const value = e.target.value
        setSelectedCampaign( value ) 
    }

    const getUniqueCompanies = useCallback( ( cardsArray ) => {

        //GETS UNIQUE COMPANIES FOR ALL AVATARS
        const uniqueCompanies = []

        cardsArray.forEach( card => {

            if( ! uniqueCompanies.includes( card.data.companyName ) ){
                //IF ITEM IS NOT IN uniqueCompanies ARRAY, WILL BE ADDED TO ARRAY
                uniqueCompanies.push( card.data.companyName )
            }
        })
        //SETS uniqueCompanies ARRAY 
        setCompanies( uniqueCompanies )

    }, [] )

    const getCompanyCampains = useCallback( ( selectedCompany ) => {

        //GETS CAMPAIGNS FOR THIS COMPANY
        const uniqueCampaigns = []

        cardsArray.forEach( card => {

            if( card.data.companyName === selectedCompany) {

                if( ! uniqueCampaigns.includes( card.data.campaignName ) ){
                    //IF ITEM IS NOT IN uniqueCompanies ARRAY, WILL BE ADDED TO ARRAY
                    uniqueCampaigns.push( card.data.campaignName )
                }
            }

        })

        //SETS uniqueCompanies ARRAY 
        setCampaigns( uniqueCampaigns )

    }, [ cardsArray ] )

    const handleResetFilters = () => {
        setSelectedCompany('')
        setSelectedCampaign('')
        setFilteredCards( [] )
        setIsChecked( false )
    }

    const filterByCompany = useCallback( ( selectedCompany ) => {
        const filteredArray = cardsArray.filter( card => card.data.companyName === selectedCompany )
        return filteredArray
        // setFilteredCards( filteredArray );

    }, [ cardsArray ] )

    const filterByCampaign = useCallback( ( selectedCampaign ) => {
        
        const array = filterByCompany( selectedCompany )
        const filteredArray = array.filter( card => card.data.campaignName === selectedCampaign )
        return filteredArray

    }, [ filterByCompany, selectedCompany ])

    const filterFavorites = useCallback(() => {

        if( filteredCards.length > 0 ){

            const filteredArray = filteredCards.filter( card => card.data.favorite === true )
            setDisplayCards( filteredArray );

        } else {
            const filteredArray = cardsArray.filter( card => card.data.favorite === true )
            setDisplayCards( filteredArray );
        }
    }, [ cardsArray, filteredCards ])

    const handleCheckbox = ( e ) => {
        setIsChecked( e.target.checked )
    }

    //EFFECTS
    useEffect(() => {
        if( cardsArray && cardsArray.length > 0 )

            getUniqueCompanies( cardsArray )

    }, [ getUniqueCompanies, cardsArray ] )

    useEffect(() => {
        if( selectedCompany !== '' ) {
            getCompanyCampains( selectedCompany )
            const array = filterByCompany( selectedCompany )
            setFilteredCards( array )
        }
    }, [ selectedCompany, getCompanyCampains, filterByCompany ] )

    useEffect(() => {
        if ( selectedCampaign !== '' ) {
            const array = filterByCampaign( selectedCampaign )
            setFilteredCards( array )
        }
    }, [ selectedCampaign, filterByCampaign ])

    useEffect(() => {
        if ( isChecked ) {
            filterFavorites()
        }
        else {
            if( filteredCards.length > 0 ){
                setDisplayCards(filteredCards)
            } else {
                setDisplayCards( cardsArray )
            }
        }

    }, [ isChecked, filterFavorites, filteredCards, cardsArray ])

    useEffect(() => {
        if ( filteredCards.length > 0) {
            setDisplayCards( filteredCards )
        } else {
            setDisplayCards( cardsArray )
        }
    }, [ filteredCards, cardsArray ] )

    useEffect(() => {
        const getPendingOrders = async ( accountId ) => {
            try{
                const orders = await getAccountOrders( accountId )
                const incompleteOrders = orders.filter( order =>  order.docId === null )
                const incompleteCount = incompleteOrders.length
                setPendingProducts( incompleteCount )

            } catch (err) {
                throw err
            }
        }

        if ( globalUser && authToken  ) {
            getPendingOrders( globalUser.account )
        }
    }, [ globalUser, getAccountOrders, authToken ])

    useEffect(() => {
        if( displayCards?.length > 0 ){
            const favsArray = displayCards.filter(( card ) => card.data.favorite === true )
            setShowFavFilter( favsArray.length > 0 ? true : false )
        }
    }, [ displayCards ])

    return(
        <div className={`avatar-cards-container `}>
            {
                displayCards?.length > 0 &&
                <FilterBar companies={ companies } selectedCompany={ selectedCompany } handleSelectCompany={ handleSelectCompany } campaigns={ campaigns } selectedCampaign={ selectedCampaign } handleSelectCampaign={ handleSelectCampaign } handleResetFilters={ handleResetFilters } handleCheckbox={ handleCheckbox } isChecked={ isChecked } showFavFilter={ showFavFilter } />
            }

            {   pendingProducts > 0 &&

                <div className="product-pending-message-container">
                    <div className="message-container round-div">
                        <p>{`You have ${ pendingProducts } ${ pendingProducts === 1 ? 'product' : 'products'} being processed. For details, check the`}<Link to={'/orders'}>Orders section</Link></p>
                    </div>
                </div>
            }
            { isLoading ?
                //WILL RENDER LOADER COMPONENT IF isLoading IS TRUE.
                <Loader/>
                :
                <>
                {   ! displayCards.length > 0 ?
                        <NoDataMessage darkMode={ darkMode }/>
                    :
                    <>
                    {   displayCards.map(( avatar, idx ) => {
                            return(
                                <AvatarCard avatar={ avatar } key={idx} darkMode={ darkMode }  handleNameUpdate={ handleNameUpdate }  />
                            )
                        })
                    }
                    </>                
                }
                </>
            }
        </div>
    )
}