import { useContext } from "react"
import { AppContext } from "../../context/AppContext"

export default function GlobalLoader() {

    //CONTEXT
    const { darkMode } = useContext( AppContext )

    return(
        <div className={`global-loader-container ${ !darkMode ? 'light' : ''}`}>
            <div className="loader-main-container flex-row centered">
                <div className="loader global"></div>
            </div>
        </div>
    )
}