import { useContext, useState, useEffect, useCallback } from "react"
import { AppContext } from "../../context/AppContext"
import Loader from "../Loader/Loader"
import BackBtn from '../BackBtn/BackBtn'
import Swal from "sweetalert2"
import useAccounts from "../../hooks/useAccounts"
import { useNavigate } from "react-router-dom"
import AdminItem from "../AdminItem/AdminItem"

export default function UsersContainer() {

    //STATE
    const [ isLoading, setIsLoading ] = useState( true )
    const [ allUsers, setAllUsers ] = useState( null )
    
    //CONTEXT
    const { darkMode } = useContext( AppContext ) 

    //ROUTER
    const navigate = useNavigate()

    //HOOKS
    const { getAllUsers, updateUserPermissions } = useAccounts()

    //FUNCTIONS
    const handlePermissions = async ( itemId, status, email ) => {
        try {
            setIsLoading( true )
            await updateUserPermissions( itemId, status, email )
            //UPDATES USERS COMPONENT DATA
            const data = await getAllUsers()
            setAllUsers( data )
            setIsLoading( false )

        } catch ( error ) {
            setIsLoading( false )
            Swal.fire({
                title: 'Oops!',
                text: 'Users status could not be updated',
                icon: 'error',
                confirmButtonText: 'Ok',
                buttonsStyling: false,
                customClass: {
                    popup: 'marketsauce-alert-container round-div div-shadow',
                    icon: 'alert-icon',
                    confirmButton: 'confirm-btn btn order2',
                    denyButton: 'deny-btn btn order1',
                }
            })
        }
    }

    const getUsers = useCallback( async () => {
        try {
            const data = await getAllUsers()
            setAllUsers( data )
            setIsLoading( false )

        } catch ( error ) {
            setIsLoading( false )
            Swal.fire({
                title: 'Oops!',
                text: 'We could not retrieve users',
                icon: 'error',
                confirmButtonText: 'Ok',
                buttonsStyling: false,
                customClass: {
                    popup: 'marketsauce-alert-container round-div div-shadow',
                    icon: 'alert-icon',
                    confirmButton: 'confirm-btn btn order2',
                    denyButton: 'deny-btn btn order1',
                }
            })
        }
    }, [ getAllUsers ] )

    //EFFECTS
    useEffect(() => {

        getUsers()

    }, [ getUsers ] )

    return(
        <div className={`admin main-container ${ !darkMode ? 'light' : '' }`}>
            <div className="inner-container round-div div-shadow">
            <BackBtn action={ ()=> navigate('/admin') }/>
                
                {   isLoading ? 
                    <Loader/>
                    :
                    <>
                    { allUsers && allUsers.length > 0 &&
                        <>
                            <h5>Users</h5>
                            <div className="item-list-container">
                                <div className='admin-item header' >
                                    <p className="size-l">User</p>
                                    <p className="size-s">Admin</p>
                                </div>
                                {   
                                    allUsers.map( ( user, idx ) => {
                                        const displayName = `${ user.name } ${ user.lastname } (${ user.email })`
                                        return(
                                            <AdminItem key={ idx } action={ handlePermissions } displayName={ displayName } status={ user.isAdmin } itemId={ user.id } email={ user.email }/>
                                        )
                                    })
                                }
                            </div>
                        </>
                    }
                    </>
                }

            </div>

        </div>
    )
}