import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCirclePlus, faXmark } from '@fortawesome/free-solid-svg-icons'

export default function NewInput({ h4, submitFunction, placeholder }) {

    //STATE
    const [ showInput, setShowInput ] = useState( false )
    const [ inputValue, setInputValue ] = useState('')


    //FUNCTIONS
    const toggleShowInput = () => {
        setShowInput( !showInput )
    }

    const handleChange = ( e ) => {

        //DESTRUCTURES event FROM INPUT
        const { value } = e.target

        //UPDATES  STATE
        setInputValue( value )
    }

    const handleSubmit = (e) => {

        e.preventDefault()
        //CALLS PROPS FUNCTION
        submitFunction( inputValue )
    }

    return(
        <div className="new-input-container round-div">
            {  !showInput ?

                <button onClick={ toggleShowInput }>
                    <h4>{ h4 }</h4>
                    <div className="add-company-btn" >
                        <FontAwesomeIcon icon={ faCirclePlus }/>
                    </div>
                </button>
                :
                <div className="input-container">
                    <div className="close-input-btn">
                        <FontAwesomeIcon icon={ faXmark } onClick={ toggleShowInput }/>
                    </div>
                    <div className="input-row">
                        <form onSubmit={ handleSubmit }>
                            <input type="text" value={ inputValue } onChange={ handleChange } placeholder={ placeholder } />
                        </form>
                        {
                            inputValue.length > 0 &&

                            <div className="add-value-btn" onClick={ handleSubmit }>
                                <FontAwesomeIcon icon={ faCirclePlus } />
                            </div>
                        }

                    </div>
                </div>
            }

        </div>
    )
}