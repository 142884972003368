import { useCallback, useContext, useEffect, useState } from "react"
import CampaignRow from "../CampaignRow/CampaignRow"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp, faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import Swal from "sweetalert2"
import useCompanies from "../../hooks/useCompanies"
import { AppContext } from "../../context/AppContext"

export default function CampaignsContainer({ companyId, toggleLoader }) {

    //STATES
    const [ newCampaignValue, setNewCampaignValue ] = useState('')
    const [ campaignsArray, setCampaignsArray ] = useState( [] )
    const [ showCampaigns, setShowCampaigns ] = useState( true )

    //HOOKS
    const { createCampaign, getCompanyCampaigns, updateCampaignById, deleteCampaignById } = useCompanies()

    //CONTEXT
    const { capitalizeWords } = useContext( AppContext )

    //FUNCTION
    const handleChange = ( e ) => {

        e.preventDefault()

        const { value } = e.target
        setNewCampaignValue( value )
    }

    const toggleShowCampaigns = () => {
        setShowCampaigns( !showCampaigns )
    }

    const handleSubmitNew = async ( e ) => {

        //PREVENTS BROWSER FROM RELOADING
        e.preventDefault()

        if ( newCampaignValue.length > 0 ){

            //TURNS LOADER ON
            toggleLoader( true )

            //RESETS INPUT VALUE
            setNewCampaignValue('')

            //CREATE NEW CAMPAIGN
            const newCampaign = {
                displayName: capitalizeWords( newCampaignValue ),
                companyId: companyId
            }
            await createCampaign( newCampaign )
        
            //UPDATES CAMPAIGNS
            updateCampaigns()

            //TURNS LOADER OFF
            toggleLoader( false )

        } else {

            //TURNS LOADER OFF
            toggleLoader( false )

            Swal.fire({
                title: 'Oops!',
                text: 'Please type a name for your campaign.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                buttonsStyling: false,
                customClass: {
                    popup: 'marketsauce-alert-container round-div div-shadow',
                    icon: 'alert-icon',
                    confirmButton: 'confirm-btn btn order2',
                    denyButton: 'deny-btn btn order1',
                }
            })
        }
    }

    const handleSubmitUpdate = async ( id, updatedName ) => {

        if( updatedName.length > 0 ) {

            //TURNS LOADER ON
            toggleLoader( true )

            //CALLS HOOK FUNCTION TO UPDATE VALUE
            await updateCampaignById( id, updatedName )

            //UPDATES CAMPAIGNS WITH NEW VALUES
            await updateCampaigns()

            //TURNS LOADER OFF
            toggleLoader( false )

        } else {

            Swal.fire({
                title: 'Oops!',
                text: 'Campaign name must have at least one character.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                buttonsStyling: false,
                customClass: {
                    popup: 'marketsauce-alert-container round-div div-shadow',
                    icon: 'alert-icon',
                    confirmButton: 'confirm-btn btn order2',
                    denyButton: 'deny-btn btn order1',
                }
            })
        }
    }

    const handleDelete =  async ( campaignId ) => {

        Swal
            .fire({
            title: 'Danger zone',
            text: 'Are you sure you want to delete this campaign?',
            icon: 'question',
            confirmButtonText: 'Delete',
            denyButtonText: 'Cancel',
            showDenyButton: true,
            buttonsStyling: false,
            customClass: {
                popup: 'marketsauce-alert-container round-div div-shadow',
                icon: 'alert-icon',
                confirmButton: 'confirm-btn btn order2',
                denyButton: 'deny-btn btn order1',
            }
            })
            .then( async ( res ) => {
                if( res.isConfirmed ){
                    try {
                        //TURNS LOADER ON
                        toggleLoader( true )

                        //CALLS HOOK FUNCTION TO DELETE CAMPAIGN
                        await deleteCampaignById( campaignId )

                        //UPDATES CAMPAIGNS
                        await updateCampaigns()

                        //TURNS LOADER OFF
                        toggleLoader( false )

                    } catch ( err ) {

                        Swal.fire({
                            title: 'Oops!',
                            text: err.message,
                            icon: 'error',
                            confirmButtonText: 'Ok',
                            buttonsStyling: false,
                            customClass: {
                                popup: 'marketsauce-alert-container round-div div-shadow',
                                icon: 'alert-icon',
                                confirmButton: 'confirm-btn btn order2',
                                denyButton: 'deny-btn btn order1',
                            }
                        })
                    }
                }
            })
    }

    const updateCampaigns = useCallback( async () => {

        //CALLS HOOK FN AND UPDATES STATE
        const campaigns = await getCompanyCampaigns( companyId )
        setCampaignsArray( campaigns );

    }, [ companyId, getCompanyCampaigns ])

    //EFFECTS
    useEffect(() => {

        updateCampaigns()

    }, [ updateCampaigns, companyId ])

    return(
        <div className="campaigns-main-container">
            <div className={`subitem-row title ${ !showCampaigns ? 'last' : ''}`}>
                <h4 className="title">Campaigns</h4>
                <div onClick={ toggleShowCampaigns } className="item-btn">
                    { !showCampaigns ?
                        <FontAwesomeIcon icon={ faChevronDown }/>
                        :
                        <FontAwesomeIcon icon={ faChevronUp }/>
                    }

                </div>
            </div>
            { showCampaigns &&
                <>
                    <div className={`subitem-row ${ campaignsArray.length === 0 ? 'last' : ''} new-campaign`}>
                        <form onSubmit={ handleSubmitNew }>
                            <input type="text" value={ newCampaignValue } name="update-input" onChange={ handleChange } placeholder="New Campaign Name"/>
                        </form>
                        <div onClick={ handleSubmitNew } className="item-btn">
                            <FontAwesomeIcon icon={ faCirclePlus }/>
                        </div>
                    </div>
                    { campaignsArray.length > 0 && campaignsArray.map(( campaign, idx ) => {
                        
                        // lastItem PROP WILL BE true IF ITEM IS THE LAST OF THE ARRAY. WILL BE USED IN COMPONENT FOR STYLE PURPOSES
                        const lastItem =  idx + 1 === campaignsArray.length ? true : false 

                        return(
                            <CampaignRow campaign={ campaign } key={ idx } lastItem={ lastItem }  handleSubmitUpdate={ handleSubmitUpdate } handleDelete={ handleDelete } />        
                        )
                    })}
                </>
            }
        </div>
    )
}