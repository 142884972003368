import { useEffect, useState } from "react"
import InviteForm from "../InviteForm/InviteForm"
import { useParams } from 'react-router-dom'
import useAccounts from "../../hooks/useAccounts"
import Swal from "sweetalert2"
import Loader from "../Loader/Loader"
import { useNavigate } from "react-router-dom"


export default function InviteContainer() {

    //STATE
    const [ isLoading, setIsLoading ] = useState( true )
    const [ account, setAccount ] = useState( null )
    const [ invitation, setInvitation ] = useState( null )
    const [ formData, setFormData ] = useState({
        name: '',
        lastname: '',
        password: '',
        checkPassword: ''
    })

    //ROUTER
    const { id, token } = useParams();
    const navigate = useNavigate()

    //HOOKS
    const { getAccountById, getInvitationById, handleJoiningUser } = useAccounts()

    //FUNCTION
    const handleChange = ( e ) => {

        //DESTRUCTURES event FROM INPUT
        const { name, value } = e.target

        //UPDATES formData STATE
        setFormData( prevFormData => ({
            ...prevFormData,
            [ name ]: value
        }))
    }


    const handleSubmit = async ( e ) => {

        e.preventDefault()

        if( formData.checkPassword === formData.password ) {
            
            try {
                setIsLoading( true )
                await handleJoiningUser( formData )
                setIsLoading( false )
                navigate('/')
    
            } catch ( err ) {
                setIsLoading( false )
                    Swal.fire({
                        title: 'Oops!',
                        text: err.message,
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        buttonsStyling: false,
                        customClass: {
                            popup: 'marketsauce-alert-container round-div div-shadow',
                            icon: 'alert-icon',
                            confirmButton: 'confirm-btn btn order2',
                            denyButton: 'deny-btn btn order1',
                        }
                    })
            }
        } else {

            Swal.fire({
                title: 'Oops!',
                text: `Your passwords don't match, please type them again.`,
                icon: 'error',
                confirmButtonText: 'Ok',
                buttonsStyling: false,
                customClass: {
                    popup: 'marketsauce-alert-container round-div div-shadow',
                    icon: 'alert-icon',
                    confirmButton: 'confirm-btn btn order2',
                    denyButton: 'deny-btn btn order1',
                }
            })
        }
    }

    useEffect(() => {

        const getInviteInfo = async () => {
        
            try {
                const account = await getAccountById( id )
                const invite = await getInvitationById( token )
                console.log(account);
                console.log(invite);

                setAccount( account )
                setInvitation( invite )

                setIsLoading( false )

            } catch ( err ) {

                setIsLoading( false )
                Swal.fire({
                    title: 'Oops!',
                    text: err.message,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    buttonsStyling: false,
                    customClass: {
                        popup: 'marketsauce-alert-container round-div div-shadow',
                        icon: 'alert-icon',
                        confirmButton: 'confirm-btn btn order2',
                        denyButton: 'deny-btn btn order1',
                    }
                })
            }
        }
        if ( id && token ) {
            getInviteInfo()    
        }

        
    }, [ id, token, getAccountById, getInvitationById ])

    useEffect(() => {

        if( account && invitation){

            //UPDATES formData STATE
            setFormData( prevFormData => ({
                ...prevFormData,
                account: account.id,
                email: invitation.email,
                token: invitation.token
            }))

            setIsLoading( false )
        }
    }, [ account, invitation ])

    return(
        <div className="view-container login">
            { isLoading ?
                <Loader/>
                :
                <>
                    { account && invitation ?
                        <InviteForm formData={ formData } handleChange={ handleChange } handleSubmit={ handleSubmit } accountName={account.name}/>
                        :
                        <div className="invalid">
                            <h3>Oops!</h3>
                            <p>The invitation is invalid. Please contact the account administrator or email us at hey@marketsauce.ai</p>
                        </div>
                    }
                    
                </>
            }
        </div>
    )
}
