import { useState, useContext, useEffect } from "react";
import Loader from "../Loader/Loader";
import ItemRow from "../ItemRow/ItemRow";
import useCompanies from "../../hooks/useCompanies";
import { AppContext } from "../../context/AppContext";
import NewInput from "../NewInput/NewInput";
import Swal from "sweetalert2";
import BackBtn from "../BackBtn/BackBtn";
import { useNavigate } from "react-router-dom";

export default function CompaniesContainer() {

    //STATE
    const [ isLoading, setIsLoading ] = useState( false )
    const [ sortDefault, setSortDefault ] = useState( true )
    const [ displayCompanies, setDisplayCompanies ] = useState( [] )

    //ROUTER
    const navigate = useNavigate()

    //HOOKS
    const { createCompany, accountCompanies, updateCompanyById, deleteCompanyById } = useCompanies()

    //CONTEXT
    const { darkMode } = useContext( AppContext )

    //FUNCTIONS
    const toggleLoader = ( bool ) => {
        setIsLoading( bool )
    }

    const toggleFilter = () => {
        setSortDefault( !sortDefault )
    }

    const handleCreateCompany = async ( input ) => {

        //TURNS LOADER ON
        setIsLoading( true )

        //CALLS HOOK FUNCTION
        await createCompany( input )

        //TURNS LOADER ON
        setIsLoading( false )
    }

    const handleDeleteCompany = async ( companyId ) => {
        Swal
            .fire({
            title: 'Danger zone',
            text: 'Are you sure you want to delete this company?',
            icon: 'question',
            confirmButtonText: 'Delete',
            denyButtonText: 'Cancel',
            showDenyButton: true,
            buttonsStyling: false,
            customClass: {
                popup: 'marketsauce-alert-container round-div div-shadow',
                icon: 'alert-icon',
                confirmButton: 'confirm-btn btn order2',
                denyButton: 'deny-btn btn order1',
            }
            })
            .then( async ( res ) => {
                if( res.isConfirmed ){
                    try {
                        //TURNS LOADER ON
                        toggleLoader( true )

                        //CALLS HOOK FUNCTION TO DELETE CAMPAIGN
                        await deleteCompanyById( companyId )

                        //TURNS LOADER OFF
                        toggleLoader( false )

                    } catch ( err ) {

                        Swal.fire({
                            title: 'Oops!',
                            text: err.message,
                            icon: 'error',
                            confirmButtonText: 'Ok',
                            buttonsStyling: false,
                            customClass: {
                                popup: 'marketsauce-alert-container round-div div-shadow',
                                icon: 'alert-icon',
                                confirmButton: 'confirm-btn btn order2',
                                denyButton: 'deny-btn btn order1',
                            }
                        })

                    }
                }
            })
    }

    //EFFECTS
    useEffect(() => {
        if ( accountCompanies && accountCompanies.length > 0 ) {
            if( sortDefault ){

                //SORTS BY CREATION DATE
                accountCompanies.sort(( a, b ) => b.timestamp - a.timestamp )
                setDisplayCompanies( [...accountCompanies] )
            } else {

                //SORTS BY DISPLAYNAME
                accountCompanies.sort(( a, b ) => {
                    const nameA = a.displayName.toLowerCase()
                    const nameB = b.displayName.toLowerCase()
                    if( nameA < nameB ){
                        return -1
                    } else if( nameA > nameB ){
                        return 1
                    }
                    return 0
                })
                setDisplayCompanies( [...accountCompanies] )
            }
        }
    }, [ accountCompanies, sortDefault ] )

    return(
        <div className={`companies main-container ${ !darkMode ? 'light' : '' }`}>
            <BackBtn action={ ()=> navigate('/settings') }/>
            <div className="inner-container round-div div-shadow">
                {   isLoading ? 
                    <Loader/>
                    :
                    <>
                        <NewInput h4={ 'Add Company' } placeholder={'Company Name'} submitFunction={ handleCreateCompany } toggleLoader={ toggleLoader } />
                        <div className="item-list-container">
                            {   displayCompanies?.length > 0 &&

                                <div className="toggle-btn-container">
                                    <p className="bold">Sort by: </p>
                                    <p className={`${ sortDefault ? 'inactive' : ''}`}>Name</p>
                                    <div className={`toggle-btn ${ sortDefault ? 'default' : ''}`} onClick={ toggleFilter }>
                                        <span></span>
                                    </div>
                                    <p className={`${ sortDefault ? '' : 'inactive'}`}>Date Created</p>
                                </div>
                            }
                            {
                                displayCompanies?.length > 0 && displayCompanies.map(( item, idx) => {
                                    return(

                                        <ItemRow key={ idx } item={ item } toggleLoader={ toggleLoader } editItem={ updateCompanyById } deleteItem={ handleDeleteCompany } editable={ true }/>
                                    )
                                })
                            }
                        </div>
                    </>
                }

            </div>

        </div>
    )
}