import { useContext } from "react"
import { AppContext } from "../../context/AppContext"
import { Link } from "react-router-dom"

export default function CreateUserInfo() {

    //CONTEXT
    const { globalUser, capitalizeWords, globalAccount } = useContext( AppContext )
    return(
        <div className="create-user-info-container">
            <h4>{`Welcome ${ capitalizeWords(globalUser.name)} ${ capitalizeWords( globalUser.lastname )}`}</h4>
            <p className={ !globalAccount.credits > 0 ? 'insufficient': 'sufficient' }>Available Credits: { globalAccount.credits }</p>
            <div className="credit-info">
                <Link className="main-btn btn get-credits-btn" to={'/settings/credits'}>
                    Buy Credits
                </Link>
            </div>
        </div>
    )
}