import { useContext, useEffect, useState } from "react"
import BlueprintForm from "../BlueprintForm/BlueprintForm"
import ProgressBtnBar from "../ProgressBtnBar/ProgressBtnBar"
import Loader from '../Loader/Loader'
import { AppContext } from "../../context/AppContext"
import Swal from 'sweetalert2'
import { useNavigate, Link } from "react-router-dom"
import useCompanies from "../../hooks/useCompanies"
import useOrders from "../../hooks/useOrders"


export default function BlueprintFormContainer({ progress, handleProgress, userHasCredits, selectedProduct, handleChange, formData, handleCompany, handleCampaign, handleSelectedFile, selectedFile }) {

    //STATE
    const [ isLoading, setIsLoading ] = useState( false ) 
    const [ sectionComplete, setSectionComplete ] = useState( false )
    const [ companies, setCompanies ] = useState( [] )

    //HOOKS
    const { getAccountCompanies } = useCompanies()
    const { createBlueprintOrder } = useOrders()

    //ROUTER
    const navigate = useNavigate()
    
    //CONTEXT
    const { updateGlobalAccount, darkMode, globalAccount } = useContext( AppContext )

    //FUNCTIONS
    const handleSubmit = async ( e ) => {

        //PREVENTS BROWSER FROM RELOADING
        e.preventDefault()

        if( userHasCredits ) {
            //TURNS LOADER ON
            setIsLoading( true )

            try {
                //CREATES ORDER
                const productPrice = 1
                await createBlueprintOrder( formData, selectedFile, productPrice )

                //UPDATES CONTEXT USER INFO
                await updateGlobalAccount( globalAccount.id )

                //TURNS LOADER OFF
                setIsLoading(false)

                //SHOOTS ALERT WITH CONFIRMATION MESSAGE
                Swal.fire({
                    // title: 'Welcome',
                    text: 'Your order has been received. You will be redirected to your Home screen now.',
                    icon: 'success',   
                    buttonsStyling: false,
                    timer: 2600,
                    showConfirmButton: false,
                    customClass: {
                        popup: 'marketsauce-alert-container round-div div-shadow',
                        icon: 'alert-icon',
                        confirmButton: 'confirm-btn btn order2',
                        denyButton: 'deny-btn btn order1',
                    }
                })

                //NAVIGATES TO HOME
                navigate('/')

            } catch ( err ) {

                //TURNS LOADER OFF
                setIsLoading(false)

                //SHOWS ALERT
                Swal.fire({
                    title: 'Oops!',
                    text: err.message,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    buttonsStyling: false,
                    customClass: {
                        popup: 'marketsauce-alert-container round-div div-shadow',
                        icon: 'alert-icon',
                        confirmButton: 'confirm-btn btn order2',
                        denyButton: 'deny-btn btn order1',
                    }
                })

                //NAVIGATES TO HOME
                navigate('/')
            }

        } else {
            //IF USER CREDITS ARE NOT ENOUGH
            Swal.fire({
                title: 'Oops!',
                text: 'Insufficient funds.',
                icon: 'warning',
                timer: 2500,
                confirmButtonText: 'Ok',
                buttonsStyling: false,
                customClass:{
                    confirmButton: 'btn btn-primary alert-btn',
                    popup: 'alert-container'
                },
                background: '#0D002A',
                iconColor: '#ffffff',
                color: '#ffffff',
            })
            //NAVIGATES TO HOME
            navigate('/create')   
        }
    }


    //EFFECTS
    useEffect(() => {
        
        if( progress === 1){
            if( formData.companyName !==  '' && formData.campaignName !==  '' ){
                setSectionComplete( true )
            } else setSectionComplete( false )
        } else if( progress === 2 ){
            if( formData.product !== '' ){
                setSectionComplete( true )
            } else setSectionComplete ( false )
        } else if( progress === 3 ){
            if( formData.context !== '' || selectedFile !== null ){
                setSectionComplete( true )
            } else setSectionComplete ( false )
        } 
    }, [ formData, progress, selectedFile ])

    useEffect(() => {

        //GETS COMPANIES FROM ACCOUNT
        const getCompanies = async () => {

            //TURNS LOADER ON
            setIsLoading( true )

            //GETS DATA FROM HOOK AND STORES IT IN STATE
            const data = await getAccountCompanies()
            setCompanies( data )

            //TURNS LOADER OFF
            setIsLoading( false )
        }

        getCompanies()

    }, [ getAccountCompanies ])
        
    return(
        <div className="brief-form-container round-div div-shadow view-inner-container">
            {  isLoading ?

                <Loader/>
                :
                <>     
                {
                    companies && companies.length > 0 ?
                    <>
                        <BlueprintForm formData={ formData } progress={ progress } handleChange={ handleChange } companies={ companies } handleCompany={ handleCompany } handleCampaign={ handleCampaign } handleSelectedFile={handleSelectedFile} selectedFile={selectedFile}/>
                        <ProgressBtnBar handleProgress={ handleProgress } sectionComplete={ sectionComplete } progress={ progress } handleSubmit={ handleSubmit } />            
                    </>
                    :
                    <div className={`no-data-message ${ !darkMode ? 'light' : ''}`}>
                        <h3>You need to create at least one Company and one Campaign to proceed</h3>
                        <p>Please go to Companies section to create them:</p>
                        <Link to={'/settings/companies'}>
                            <button className="btn view-cta">Create Company</button>
                        </Link>
                    </div>
                }
                </>

            }
        </div>
    )
    
}