import { useContext } from "react"
import { AppContext } from "../../context/AppContext"
import SideBarToggleBtn from "../SideBarToggleBtn/SideBarToggleBtn"
import SideBar from "../SideBar/SideBar"

export default function SideBarContainer() {

    //CONTEXT
    const { sideBarExpanded, darkMode } = useContext(AppContext)

    return(
        <div className={`sidebar-main-container ${ sideBarExpanded ? 'expanded' : 'shrink'} ${ !darkMode ? 'light' : ''}`}>
            <SideBarToggleBtn/>
            <SideBar/>
        </div>
    )
}