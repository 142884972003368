import { createContext, useState , useEffect, useCallback } from "react"
import { onAuthStateChanged, getAuth, signOut } from 'firebase/auth'
import Swal from "sweetalert2"
import useAvatars from "../hooks/useAvatars"
import useAccounts from "../hooks/useAccounts"
import app from "../fb"
import { useNavigate } from "react-router-dom"

const AppContext = createContext('')
const { Provider } = AppContext

const AppProvider = ({ children }) => {

    //FIREBASE
    const auth = getAuth(app)

    //STATES
    const [ sideBarExpanded, setSideBarExpanded ] = useState( true )
    const [ userAvatars, setUserAvatars ] = useState( null )
    const [ globalLoader, setGlobalLoader ] = useState( false )
    const [ globalUser, setGlobalUser ] = useState( null )
    const [ darkMode, setDarkMode ] = useState( () => {
        const storageMode = localStorage.getItem('darkMode')
        return storageMode ? JSON.parse( storageMode ) : true 
    } )
    const [ globalAccount, setGlobalAccount ] = useState( null )
    const [ authToken, setAuthToken ] = useState( null )
    const [ authUserEmail, setAuthUserEmail ] = useState( null )
    const [ populateProfile, setPopulateProfile ] = useState( false )
    

    //HOOKS
    const { avatars, fetchAccountAvatars } = useAvatars()
    const { getUserByEmail, getAccountById } = useAccounts()
    
    //ROUTER
    const navigate = useNavigate()
    
    //FUNCTIONS
    const deleteSessionData =  () => {
        setUserAvatars( [] )
        setGlobalUser( null )
        setAuthToken( null )
        setGlobalAccount( null )
        navigate('/')
    }

    const addZero = ( input ) =>{
        
        if( input < 10 ){
            let output = '0'+ input
            return output
        }
        else return input
    }

    const capitalizeWords = ( string ) =>{
        return(
            string
                .toLowerCase()
                .split(' ')
                .map(( word ) => word.charAt( 0 ).toUpperCase() + word.slice( 1 ))
                .join(' ')
        )
    }
    
    const toggleSideBar = () => {
        setSideBarExpanded( !sideBarExpanded )
    }

    const toggleDarkMode = () => {
        const updatedMode = !darkMode
        localStorage.setItem('darkMode', JSON.stringify( updatedMode ))
        setDarkMode( updatedMode )
    }

    const updateContextAvatars = ( array ) => {
        setUserAvatars( array )
    }
    
    const updateGlobalAccount = useCallback( async ( accountId ) => {

        try {
            const globalAccount = await getAccountById( accountId, authToken )
            setGlobalAccount( globalAccount )

        } catch ( err ) {
            console.log( err );
        }
    }, [ getAccountById, authToken ])


    
    const updateGlobalUser = useCallback( async ( email, token ) => {
        const maxRetries = 10
        const retryDelay = 2000

        const retryGetUserByEmail = async  ( attempt = 1 ) => {
            try {
                const authenticatedUser = await getUserByEmail( email, token )

                if ( authenticatedUser ) {
                    setGlobalUser(authenticatedUser)
                    return
                } else if ( attempt < maxRetries ) {
                    await new Promise( resolve => setTimeout( resolve, retryDelay ))
                    return retryGetUserByEmail( attempt + 1 )
                } else {
                    throw new Error('Max retries reached. No user found.')
                }
            } catch ( error ) {
                setGlobalLoader( false )
                Swal.fire({
                    title: 'Oops!',
                    text: error.message,
                    icon: 'warning',
                    timer: 1500,
                    buttonsStyling: false,
                    showConfirmButton: false,
                    customClass: {
                        popup: 'marketsauce-alert-container round-div div-shadow',
                        icon: 'alert-icon',
                        confirmButton: 'confirm-btn btn order2',
                        denyButton: 'deny-btn btn order1',
                    },
                })
            }
        }

        retryGetUserByEmail()

    }, [ getUserByEmail ] )
    
    
    //EFFECTS
    useEffect(()=>{
        //IF AVATAR CHANGES, UPDATES userAvatars STATE
        setUserAvatars( avatars )
    
    },[ avatars ])

    useEffect(() => {
        const unsubscribe = onAuthStateChanged( auth, async ( user ) => {
            if ( user ) {
                try {
                    const token = await user.getIdToken();
                    setAuthToken( token );
                    setAuthUserEmail( user.email.toLowerCase() )
                    setPopulateProfile( true )
                } catch (error) {
                    console.error( "Error getting ID token:", error );
                }
            } else {
                await signOut( auth );
                setPopulateProfile( false )
                setAuthToken( null );
                setGlobalUser( null );
                setGlobalAccount( null );
                navigate('/');
            }
        });
    
        return () => unsubscribe();
    }, [ auth, navigate, getUserByEmail ]);

    useEffect(() => {
        if ( authToken && authUserEmail && populateProfile ){
            updateGlobalUser( authUserEmail, authToken )
        }
    }, [ authToken, updateGlobalUser, authUserEmail, populateProfile ])

    useEffect(() => {
        if ( globalUser ){
            updateGlobalAccount( globalUser.account )
        }
    }, [ globalUser, updateGlobalAccount ])

    useEffect(() => {
        if ( globalAccount && authToken ){
            fetchAccountAvatars( globalAccount.id, authToken )
        }
    }, [ globalAccount, fetchAccountAvatars, authToken ])

    useEffect(() => {

    })


    return(

        <Provider value={{
            globalUser,
            toggleSideBar,
            sideBarExpanded,
            userAvatars,
            addZero,
            capitalizeWords, 
            globalLoader,
            darkMode,
            toggleDarkMode,
            globalAccount,
            updateGlobalAccount,
            updateContextAvatars,
            authToken,
            deleteSessionData,
            setPopulateProfile
        }}>
            {children}
        </Provider>
    )


}

export { AppContext, AppProvider }