import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisV, faTrashCan, faPenToSquare, faXmark, faCheck } from '@fortawesome/free-solid-svg-icons'
import { useContext, useState } from "react"
import CampaignsContainer from "../CampaignsContainer/CampaignsContainer"
import { AppContext } from "../../context/AppContext"

export default function ItemRow({ editItem, deleteItem, item, toggleLoader, editable }) {
    
    //STATES
    const [ showMainOpt, setShowMainOpt ] = useState( false )
    const [ showEdit, setShowEdit ] = useState( false )
    const [ updateItemValue, setUpdateItemValue ] = useState( item.displayName )

    //CONTEXT
    const { capitalizeWords } = useContext( AppContext )

    //FUNCTIONS
    const toggleMainOpt = () => {
        setShowMainOpt( !showMainOpt )
    }

    const toggleEdit = () => {
        setShowEdit( !showEdit )
    }

    const handleItemChange = ( e ) => {
        const { value } = e.target
        setUpdateItemValue( value )
    }

    const handleUpdateItem = async ( e ) => {
        //PREVENTS BROWSER FROM RELOADING
        e.preventDefault()

        //TURNS LOADER ON
        toggleLoader( true )

        //CALLS EDIT VALUE FN
        await editItem( item.id, updateItemValue )

        //TURNS LOADER OFF
        toggleLoader( false )
    }

    const handleDeleteItem = async ( ) => {

        //TURNS LOADER ON
        toggleLoader( true )

        //CALLS EDIT VALUE FN
        await deleteItem( item.id )

        //TURNS LOADER OFF
        toggleLoader( false )
    }

    return(
        <>
            <div className={`item-row round-div ${ item.campaigns ? 'trimmed' : ''}`}>
                <>
                { !showEdit ?

                    <p>{ capitalizeWords( item.displayName ) }</p>
                    :
                    <form onSubmit={ handleUpdateItem }>
                        <input type="text" value={ updateItemValue } onChange={ handleItemChange } name="update-input"/>
                    </form>
                }
                </>
                { editable &&

                    <div className="btn-container">
                        { ! showMainOpt ?

                            <div className="item-btn" onClick={ toggleMainOpt }>
                                <FontAwesomeIcon icon={ faEllipsisV }/>
                            </div>
                            :
                            <div className="btn-container">
                                { !showEdit ?
                                    <>
                                        <div className="item-btn">
                                            <FontAwesomeIcon icon={ faTrashCan } onClick={ handleDeleteItem }/>
                                        </div>
                                        <div className="item-btn" >
                                            <FontAwesomeIcon icon={ faPenToSquare } onClick={ toggleEdit }/>
                                        </div>
                                        <div className="item-btn" onClick={ toggleMainOpt }>
                                            <FontAwesomeIcon icon={ faXmark }/>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="item-btn" >
                                            <FontAwesomeIcon icon={ faCheck } onClick={ handleUpdateItem }/>
                                        </div>
                                        <div className="item-btn" onClick={ toggleEdit }>
                                            <FontAwesomeIcon icon={ faXmark }/>
                                        </div>
                                    </>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
            <>
            { item.campaigns &&
                <CampaignsContainer companyId={ item.id } toggleLoader={ toggleLoader } />
            }
            </>
        </>
    )
}