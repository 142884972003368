import { useContext, useState } from "react"
import { AppContext } from "../../context/AppContext"
import MobileMenu from "../MobileMenu/MobileMenu"
import LogoContainer from "../LogoContainer/LogoContainer"
import MenuBtn from "../MenuBtn/MenuBtn"

export default function TopBarContainer() {

    //STATE
    const [ visible, setVisible ] = useState( false )

    //CONTEXT
    const { darkMode } = useContext( AppContext )

    //FUNCTIONS
    const toggleVisible = () => {
        setVisible( !visible )
    }

    return(
        <>
            <div className={`topbar-main-container  ${ !darkMode ? 'light' : ''}`}>
                <LogoContainer desktop={ false }/>
                <MenuBtn toggleVisible={ toggleVisible } visible={ visible } />
            </div>
            <MobileMenu visible={ visible } toggleVisible={ toggleVisible } />
        </>
    )
}