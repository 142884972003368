export default function ProgressBtnBar({ handleProgress, sectionComplete, progress, handleSubmit }) {
    return(
        <div className="progress-btn-container">
            {  //PREV BUTTON ONLY SHOWS IF SECTION IS > 1
                progress > 1 && 
                <button className={`btn round-btn form-progress-btn back`} onClick={ () => handleProgress( -1 ) }>Back</button>
            }
            { //PREV BUTTON ONLY SHOWS IF SECTION IS < 3
                progress < 3 &&
                <button className={`btn round-btn form-progress-btn ${sectionComplete ? '': 'disabled'}`} disabled={ !sectionComplete } onClick={ () => handleProgress( +1 ) }>Next</button>
            }
            {
                sectionComplete && progress === 3 &&
                <button type="submit" onClick={ handleSubmit } className="btn round-btn form-progress-btn">Submit</button>
            }
            {/* {
                progress === 3 &&
                <button type="submit" onClick={ handleSubmit } className="btn round-btn form-progress-btn">Submit</button>
            } */}
        </div>
    )
}