import { useState, useEffect, useContext, useCallback } from "react";
import ToggleButton from "../ToggleButton/ToggleButton";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronDown, faChevronUp, faDotCircle, faXmark } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../context/AppContext";
import useAccounts from "../../hooks/useAccounts";
import InlineLoader from "../InlineLoader/InlineLoader";


export default function AdminItem({ action, displayName, status, itemId, email, extended, defValue, submitChange, deployable }) {

    //STATE
    const [ inputValue, setInputValue ] = useState( () => defValue ? defValue : '' )
    const [ showEdit, setShowEdit ] = useState( false )
    const [ showDetails, setShowDetails ] = useState( false )
    const [ accountUsers, setAccountUsers ] = useState( null )
    // const [ detailLoader, setDetailLoader ] = useState( false )

    //CONTEXT
    const { globalAccount } = useContext( AppContext )

    //HOOKS
    const { getAccountUsers } = useAccounts()

    //FUNCTIONS
    const handleChange = ( e ) => {

        //GETS VALUE FROM INPUT
        const value = e.target.value

        if ( !isNaN( value ) ){
    
            //CONTROLLED INPUT HANDLING
            setInputValue( value )

            setShowEdit( true )

        } else {
            Swal.fire({
                title: 'Oops!',
                text: 'Input value must be a number',
                icon: 'warning',
                confirmButtonText: 'Ok',
                buttonsStyling: false,
                customClass: {
                    popup: 'marketsauce-alert-container round-div div-shadow',
                    icon: 'alert-icon',
                    confirmButton: 'confirm-btn btn order2',
                    denyButton: 'deny-btn btn order1',
                }
            })
        }

    }

    const handleCancel = () => {
        setShowEdit( false )
        setInputValue( defValue )
    }

    const handleKeys = ( e ) => {
        if ( e.key === 'Escape' ) {
            handleCancel()
        } else if ( e.key === 'Enter' ) {
            handleSubmit()
        }
    }

    const handleSubmit = () => {
        submitChange( itemId, inputValue )
    }

    const handleAccountDetails = () => {
        setShowDetails( !showDetails )
    }

    const getAccountDetails = useCallback( async () => {
        try {
            const users = await getAccountUsers( itemId )
            console.log(users);
            setAccountUsers( users )
            
        } catch (error) {
            console.log(error);
        }
    }, [ getAccountUsers, itemId ])

    //EFFECTS
    useEffect(() => {
        setInputValue( defValue )
    }, [ defValue ])

    useEffect(() => {

        if( showDetails ){
            getAccountDetails()
        }
    }, [showDetails, getAccountDetails])


    return(
        <div className='admin-item' >
            <div className="admin-inner-container">
                <div className={`more ${ extended ? "size-m": 'size-l'} `} >
                    { deployable &&
                        <FontAwesomeIcon icon={ showDetails ? faChevronUp : faChevronDown } onClick={ handleAccountDetails }/>
                    }
                    <p>{ displayName }</p>
                </div>
                {
                    extended &&
                    <div className="size-s credits-input-div">
                        <input type="text" value={ inputValue } onChange={ handleChange } onKeyDown={ handleKeys }/>
                        {
                            showEdit &&
                            <>
                                <FontAwesomeIcon icon={ faCheck } className="confirm-btn credit-btn" onClick={ handleSubmit}/>
                                <FontAwesomeIcon icon={ faXmark } className="deny-btn credit-btn" onClick={ handleCancel }/>
                            </>
                        }
                    </div>
                }
                <div className="size-s">
                    <ToggleButton status={ status } action={ action } itemId={ itemId } email={ email }/>
                </div>
            </div>
            {  showDetails &&
                <div className="admin-detail">
                    {
                        accountUsers && accountUsers.length > 0 ? 
                        <>
                            <p>Users:</p>
                            <div className="admin-users-list">
                                { 
                                    accountUsers.map(( user, idx ) => {
                                        return(
                                            <p key={ idx }>{`- ${user.name} ${user.lastname} (${user.email})`}</p>
                                            
                                        )
                                    })
                                }
                            </div>
                        </>
                        :
                        <InlineLoader/>
                        
                    }
                </div>
            }

        </div>
    )
}