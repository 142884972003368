import { initializeApp } from "firebase/app";

//Web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBZCoBNVLYPkgx42eC5jlSXkFBbvzU1JzY",
  authDomain: "marketsauce-portal.firebaseapp.com",
  projectId: "marketsauce-portal",
  storageBucket: "marketsauce-portal.appspot.com",
  messagingSenderId: "389709240740",
  appId: "1:389709240740:web:c7b6a1565f343ff0490931"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export default app