import { faChevronUp, faFilter, faStar, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export default function FilterBar({ companies, handleSelectCompany,  selectedCompany, campaigns, handleSelectCampaign, selectedCampaign, handleResetFilters, isChecked, handleCheckbox, showFavFilter }) {

    //STATE
    const [ visible, setVisible ] = useState( false )

    //FUNCTION
    const toggleVisible = () => {
        setVisible( !visible )
    }

    return(
        <div className="filter-bar-container">
            <div className={`filter-bar round-div ${ !visible ? 'hidden' : '' }`}>
                <div className="inner-div" onClick={ toggleVisible }>
                    <FontAwesomeIcon icon={ faFilter }/>
                    <p>Filters</p>
                </div>
                <span></span>
                <div className="expansible">
                    <label htmlFor="companies-select">Company</label>
                    <select
                        id={'companies-select'}
                        value={ selectedCompany }
                        name='company'
                        onChange={ handleSelectCompany }
                    >
                        <option value="" disabled> Companies </option>
                        { companies.length > 0 && companies.map(( company, idx) => {
                                return(
                                    <option value={ company } key={ idx }>{ company }</option>
                                )
                            }) 
                        }
                    </select>
                </div>
                <span></span>
                <div className="expansible">
                    <label htmlFor="companies-select">Campaign</label>
                    <select
                        id={'campaign-select'}
                        value={ selectedCampaign }
                        name='campaign'
                        onChange={ handleSelectCampaign }
                    >
                        <option value="" disabled> Campaigns</option>
                        { campaigns.length > 0 && campaigns.map(( campaign, idx) => {
                                return(
                                    <option value={ campaign } key={ idx }>{ campaign }</option>
                                )
                            }) 
                        }
                    </select>
                </div>
                <span></span>
                <div className="expansible">
                    <FontAwesomeIcon icon={ faStar }/>
                    <p>Show Favorites</p>
                    <input type="checkbox" onChange={ handleCheckbox } checked={ isChecked } disabled={ !showFavFilter }/>
                </div>
                <span></span>
                <div className="expansible" onClick={ handleResetFilters }>
                    <FontAwesomeIcon icon={ faXmark }/>
                    <p>Reset Filters</p>
                </div>
                <div className={'expansible chevron'} onClick={ toggleVisible }>
                    <FontAwesomeIcon icon={ faChevronUp }/>
                </div>
            </div>
        </div>
    )
}