import app from "../fb";
import { AppContext } from '../context/AppContext';
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid'
import { useCallback, useContext } from "react";
import axios from "axios";

function useOrders( ) {

    //FIRESTORE STORAGE INIT
    const storage = getStorage( app )

    //CONTEXT
    const { globalUser, authToken } = useContext( AppContext )

    const getAccountOrders = useCallback( async ( accountId ) => {

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/orders/account/${ accountId }`, {
                headers: { Authorization: `Bearer ${ authToken }`}
            })
            const accountOrders = res.data
            return accountOrders

        } catch ( err ) {
            throw err
        }

    }, [ authToken ])

    const createBlueprintOrder = async ( orderData, selectedFile, productPrice ) => {
        
        try {   
            //CREATES ID FOR REQUEST
            const id = uuidv4()
            //IF THERE IS A FILE, GETS THE URL 
            const attachment = await uploadTextFile( selectedFile )
            //CREATES DOC WITH FORM DATA AND USER REFERENCE
            const order = {
                user: {
                    accountId: globalUser.account,
                    userId: globalUser.id
                },
                data: {
                    ...orderData,
                    id: id,
                    attachment: attachment,
                    price: productPrice
                }
            }
            //CREATE BLUEPRINT ORDER
            await axios.post(`${process.env.REACT_APP_API_URL}/orders/blueprint`, order, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${ authToken }`
                }
            })
            
        } catch ( error ) {
            throw error
        }
    }

    const uploadTextFile = async ( file ) => {

        if( file ){
            try {
                //CREATES REFERENCE TO IMAGE USING STORAGE PATH AND NAMES IT WITH ORIGINAL NAME + UUID STRING FOR UNIQUE NAME PURPOSES
                const fileRef = ref( storage, `/blueprint-uploads/${ uuidv4() + file.name }`)
                const snapshot = await uploadBytes( fileRef, file )
                const fileUrl = await getDownloadURL( snapshot.ref )

                return fileUrl
    
            } catch ( err ) {
    
                throw err
            }
        } else {
            return ''
        }
    }

    return{
        createBlueprintOrder,
        getAccountOrders
    }
}

export default useOrders


