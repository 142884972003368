// import { NavLink } from "react-router-dom";
import { faHouse, faStar, faGear, faUsers, faCirclePlus, faBuilding, faComment, faList, faGears, faTerminal } from "@fortawesome/free-solid-svg-icons"
import SideBarBtn from "../SideBarBtn/SideBarBtn";
import { useContext } from "react"
import { AppContext } from "../../context/AppContext"
import DarkToggleBtn from "../DarkToggleBtn/DarkToggleBtn";
import SignOutBtn from "../SignOutBtn/SignOutBtn";

export default function MobileMenu({ visible, toggleVisible }) {

    //CONTEXT
    const { globalUser, darkMode } = useContext( AppContext)

    return(
        <div className={`mobilemenu-main-container ${ !darkMode ? 'light' : ''} ${ visible ? 'visible': ''}`}>
            <nav className="mobile-nav">
                <SideBarBtn icon={ faCirclePlus } text={'Create'} route={'/create'} desktop={ false } toggleVisible={ toggleVisible }/>
                <SideBarBtn icon={ faHouse } text={'Avatar Library'} route={'/'} desktop={ false } toggleVisible={ toggleVisible }/>
                <SideBarBtn icon={ faTerminal } text={'Prompt Library'} route={'/prompts'} desktop={ false }/>
                <SideBarBtn icon={ faList } text={'Orders'} route={'/orders'} desktop={ false }/>
                <SideBarBtn icon={ faUsers } text={'Community'} route={'/community'} desktop={ false } toggleVisible={ toggleVisible }/>
                <SideBarBtn icon={ faComment } text={'Chat'} route={'/chat'} desktop={ false } toggleVisible={ toggleVisible }/>
                {globalUser?.isAdmin && <SideBarBtn icon={ faGear } text={'Admin Panel'} route={'/admin'} desktop={ false } toggleVisible={ toggleVisible }/> }
                <div className="botom-options">
                    <DarkToggleBtn desktop={ false } toggleVisible={ toggleVisible }/>
                    <SideBarBtn icon={ faGears } text={'Settings'} route={'/settings'} desktop={ false } toggleVisible={ toggleVisible }/>
                    <SignOutBtn desktop={ false }/>

                </div>
            </nav>
        </div>
    )
}