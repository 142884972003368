import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

export default function Footer () {

    const { darkMode } = useContext( AppContext )
    return(
        <footer>
            <div className={`footer-main-container ${ !darkMode ? 'light' : '' }`}>
                <p>MarketSauce by Gen AI University © Copyright 2024</p>
                <Link to={ '/privacy-policy' }>Privacy Policy</Link>
            </div>
        </footer>
    )
}