export default function CommunityContainer() {
    return(
        <div className="community main-container">
            <div className="no-data-message">
                <h3>We are building this section</h3>
                <p>Stay tuned for updates!</p>
            </div>

        </div>
    )
}