import { useState, useContext } from "react"
import { AppContext } from "../../context/AppContext"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

export default function SideBarToggleBtn() {

    //CONTEXT
    const { toggleSideBar, sideBarExpanded, darkMode } = useContext(AppContext)

    return(
        <button onClick={toggleSideBar} className={`sidebar-toggle-btn btn  desktop ${ !darkMode ? 'light' : ''}`}>
            <FontAwesomeIcon icon={ sideBarExpanded ? faChevronLeft : faChevronRight} />
        </button>
    )
}