import { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";

export default function AccountInactiveMessage() {

    //STATE
    const [ inputValue, setInputValue ] = useState('')

    //CONTEXT
    const { darkMode } = useContext( AppContext )

    //FUNCTIONS
    const handleChange = ( e ) => {
        const value = e.target.value.toUpperCase()
        if( value.length <= 6){
            setInputValue( value )
        }
    }

    const handleAccountActivation = useCallback( async ( activationCode ) => {
        console.log( activationCode );
    }, [] )

    //EFFECTS
    useEffect(() => {
        if( inputValue.length === 6 ){
            handleAccountActivation( inputValue )
        }
    })


    return(

    <div className={`no-data-message ${ !darkMode ? 'light' : ''}`}>
        <h3>Your account has not been activated yet</h3>
        <p>We are reviewing your information, and access will be granted shortly</p>
        {/* <p>Enter activation code:</p>
        <input type="text" className="activation-input" value={ inputValue } onChange={ handleChange }/> */}
    </div>
    )


}