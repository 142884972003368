import { useContext, useEffect, useMemo, useState } from "react"
import { AppContext } from "../../context/AppContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/free-solid-svg-icons"
import OnboardingStep from "../OnboardingStep/OnboardingStep"
import { useNavigate } from "react-router-dom"
import useAccounts from "../../hooks/useAccounts"

export default function OnboardingContainer() {

    //STATE
    const [ hidden, setHidden ] = useState( true )
    const [ expanded, setExpanded ] = useState( false )
    const [ progress, setProgress ] = useState( () => {
        const storedValue = localStorage.getItem( 'onboardingProgress' )
        return storedValue ? JSON.parse( storedValue ) : 0
    })
    
    //CONTEXT
    const { darkMode, globalAccount } = useContext( AppContext )
    
    //VARIBALES
    const guideSteps = useMemo(() => {
        const array = [
            {   
                order: 1,
                title: 'Companies and Campaign',
                description: `Navigate to Settings > Companies and create at least a Company and a Campaign:`,
                link: '/settings/companies',
                btnText: 'Companies',
                active: true,
                video: 'https://drive.google.com/file/d/1paYGA-haYA171js7fNNBmICZpTdfUmxe/preview'
            },
            {
                order: 2,
                title: 'Place an Order',
                description: `From Create section, you can order your first product related to the Company and Campaigns you have just created:`,
                link: '/create',
                btnText: 'Create',
                active: false,
                video: 'https://drive.google.com/file/d/1hg8Soo59DP05Rubdoh9smz2pK7ZojWcf/preview'
            },
            {
                order: 3,
                title: 'Track Order Status',
                description: `You can check your Orders status from Orders section:`,
                link: '/orders',
                btnText: 'Orders',
                active: false ,
                video: 'https://drive.google.com/file/d/1sbXkEogvByqz3_HowCQn0JkmqdFjWGn-/preview'
            },
            {
                order: 4,
                title: 'Explore your Library',
                description: `In Library, you will find all the products you have received:`,
                link: '/',
                btnText: 'Library',
                active: false ,
                video: 'https://drive.google.com/file/d/1s1mqRcI1ACAbehLBnlCTj9bwBqi7MqQh/preview'
            }
        ]
         return array
    }, [] )
    
    //HOOKS
    const { updateAccountOnboarded } = useAccounts()

    //ROUTER
    const navigate = useNavigate()
    
    //FUNCTIONS
    const toggleExpanded = () => {
        setExpanded( !expanded )
    }
    
    const handleClick = ( link ) => {
        toggleExpanded()
        setProgress( progress + 1 )
        navigate( link )
    }

    //EFFECTS
    useEffect(() => {

        const revealWidget = () => {
            setTimeout(() => {
                setHidden( false )
            }, 2000);
        }
        if (globalAccount && !globalAccount.onboarded && globalAccount.isActive ){
            revealWidget()
        }
    }, [ globalAccount ] )

    useEffect(() => {

        const completeOnboarding = async () => {

            try {

                //UPDATES ACCOUNT STATUS TO onboarded: true
                const accountId = globalAccount.id
                await updateAccountOnboarded( accountId )

                setTimeout(() => {
                    setHidden( true )
                }, 3000);

            } catch (error) {
                
            }
        } 

        if( progress === guideSteps.length ){
            
            completeOnboarding()
        }

    }, [ progress, globalAccount, updateAccountOnboarded, guideSteps.length ])

    useEffect(() => {
        localStorage.setItem( 'onboardingProgress', JSON.stringify( progress < guideSteps.length ? progress : 0 ) )

    }, [ progress, guideSteps ])

    return(

        <div className={`onbaording-widget-main  ${ expanded? 'expanded': 'round-div' } ${ hidden ? 'hidden': '' } ${ !darkMode ? 'light': '' }`} >
            <div className="onboarding-inner-div round-div" onClick={ !expanded ? toggleExpanded : null }>
                {
                    ! expanded ?
                    <>
                        {
                            progress < guideSteps.length ?
                            <>
                                <h4>{progress === 0 ? 'Get Started!' : 'Complete your onboarding:'}</h4>
                                <p>{ progress === 0 ? 'Complete your Onboarding:' : `${ progress }/${ guideSteps.length } steps completed`}</p>
                                <button className="btn widget-btn">{ progress === 0 ? 'Start' : 'Continue' }</button>
                            </>
                            :
                            <>
                                <h4>Congratulations!</h4>
                                <p>You have completed your onboarding</p>
                            </>
                        }
                    </>
                    :
                    <div className="onboarding-guide">  
                        <FontAwesomeIcon icon={ faXmark } onClick={ toggleExpanded } className="close-btn"/>
                        <div className="steps-main-container">
                            <h3>Start using your account</h3>
                            <div className="steps-inner">
                                {
                                    guideSteps.map(( step, idx ) => {

                                        return(
                                            <OnboardingStep key={ idx } handleClick={ handleClick } step={ step } active={ idx === progress ? true : false } complete={ idx < progress ? true : false }/>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="video-main-container">
                            <div className="onboarding-video-container">
                                <iframe
                                src={ guideSteps[progress].video }
                                title="MarketSauce Onboarding"
                                allow="autoplay"
                                allowFullScreen>

                                </iframe>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
        
    )
}

