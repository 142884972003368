import { useContext } from "react"
import { AppContext } from "../../context/AppContext"

export default function Loader () {

    //CONTEXT
    const { darkMode } = useContext( AppContext )

    return(
        <div className={`loader-main-container flex-row centered ${ !darkMode ? 'light' : ''}`}>
            <div className="loader"></div>
        </div>
    )
}