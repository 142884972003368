import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import OnboardingStep from "../components/OnboardingStep/OnboardingStep";
import useCompanies from "../hooks/useCompanies";


export default function OnboardingGuide () {

    //STATE
    const [ currentStep, setCurrentStep ] = useState( 0 )
    const [ accountCompanies, setAccountCompanies ] = useState( [] )

    //NAVIGATION
    const navigate = useNavigate()

    //CONTEXT
    const { darkMode } = useContext( AppContext )
    
    //HOOKS
    const { getAccountCompanies } = useCompanies()

    //EFFETCS
    useEffect(() => {

        const getCompanies = async () => {
            const companies = await getAccountCompanies()
            setAccountCompanies( companies )
        }
        getCompanies()

    }, [ getAccountCompanies ])

    useEffect(()=> {
        if( accountCompanies && accountCompanies.length > 0 ){
            setCurrentStep( 1 )
        }
    }, [ accountCompanies ])

    useEffect(() => {


    }, [ currentStep ])

    //VARIABLES
    const guideSteps = [
        {   
            order: 1,
            title: 'Companies and Campaign',
            description: `Navigate to Settings > Companies and create at least a Company and a Campaign:`,
            link: '/settings/companies',
            btnText: 'Companies',
            active: true 
        },
        {
            order: 2,
            title: 'Place an Order',
            description: `From Create section, you can order your first product related to the Company and Campaigns you have just created:`,
            link: '/create',
            btnText: 'Create',
            active: false 
        },
        {
            order: 3,
            title: 'Track Order Status',
            description: `You can check your Orders status from Orders section:`,
            link: '/orders',
            btnText: 'Orders',
            active: false 
        },
        {
            order: 4,
            title: 'Explore your Library',
            description: `In Library, you will find all the products you have received:`,
            link: '/',
            btnText: 'Library',
            active: false 
        }
    ]

    return (
        <>
        <div className={`onboarding-guide-main ${ !darkMode ? 'light' : ''}`}>
            <FontAwesomeIcon icon={ faXmark } onClick={ () => navigate( '/' ) } className="close-btn"/>
            <div className="steps-main-container">
                <h3>Onboarding Guide:</h3>
                {
                    guideSteps.map(( step, idx ) => {
                        console.log('idx '+idx);

                        return(
                            <OnboardingStep key={ idx } step={ step } active={ idx === currentStep ? true : false }/>
                        )
                    })
                }
            </div>
            <div className="video-main-container">

            </div>

        </div>
        </>
    )
}